/*
 * @Description:
 * @Autor: shh
 * @Date: 2022-07-04 13:41:16
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2024-01-25 16:20:32
 */
import axios from "axios";

import router from "@/router";
import { getToken, removeToken } from "@/utils/auth";
import { MessageBox, Message } from "element-ui";
import qs from "qs";
import setting from "@/config/setting.js";
const { baseURL, requestTimeout } = window.config.api;
const {
  contentType,
  otherContentType,
  invalidCode,
  noPermissionCode,
  successCode,
  tokenStorage,
  tokenName,
} = setting;
let tokenLose = true;
const power = localStorage.getItem("power");
/**
 *
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */
const handleCode = (code, msg) => {
  switch (code) {
    case invalidCode:
      tokenLose = false;
      if (
        document.getElementsByClassName("el-message-box__wrapper").length === 0
      ) {
        MessageBox.confirm(
          "您已掉线，或者访问权限出错，请重新登录！",
          "重新登录",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(async () => {
            removeToken();
            localStorage.clear();
            router.replace({ path: "/login" });
          })
          .catch(() => {
            tokenLose = true;
          });
      }

      break;
    case noPermissionCode:
      router.push({ path: "/401" }).catch(() => {});
      break;
    default:
      Message.error(msg || `后端接口${code}异常`);
      break;
  }
};
const instance = axios.create({
  baseURL: baseURL,
  timeout: requestTimeout,
  headers: {
    "Content-Type": contentType,
  },
});

instance.interceptors.request.use(
  (config) => {
    // config.baseURL = power ? baseURL + power : baseURL
    if (localStorage[tokenStorage]) {
      config.headers[tokenName] = getToken();
    }
    if (config.data && config.headers["Content-Type"] === otherContentType) {
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  (error) => {
    return;
  }
);

instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    const { data } = response;
    const { code, success, message } = res;
    // 操作成功
    if (success) {
      if (code == "0") {
        return res;
      } else {
        if (message == "登录已过期，请重新登录") {
          removeToken();
          localStorage.clear();
          commit("RESET_STATE");
          router.replace({ path: "/login" });
        }
        Message.error(message);
        return Promise.reject();
      }
    } else {
      Message.error(`后端接口未知异常`);
      return Promise.reject();
    }
  },
  (error) => {
    const { response, message } = error;
    if (response?.data?.message) {
      Message.error(response.data.message || `后端接口未知异常`);
      if (response.data.message == "登录已过期，请重新登录") {
        removeToken();
        localStorage.clear();
        router.replace({ path: "/login" });
      } else {
        Message.error(response?.data?.message || `后端接口未知异常`);
      }
      return Promise.reject(error);
    } else {
      let { message } = error;
      if (message === "Network Error") {
        message = "后端接口连接异常";
      }
      if (message.includes("timeout")) {
        message = "后端接口请求超时";
      }
      if (message.includes("Request failed with status code")) {
        const code = message.substr(message.length - 3);
        message = "后端接口" + code + "异常";
      }
      Message.error(message || `后端接口未知异常`);
      return Promise.reject(error);
    }
  }
);
export function get(url, params) {
  return instance({
    url: url,
    method: "GET",
    params,
  });
}
export function put(url, data) {
  return instance({
    url: url,
    method: "PUT",
    data,
  });
}
export function post(url, data) {
  return instance({
    url: url,
    method: "POST",
    data,
  });
}
export function del(url, data) {
  return instance({
    url: url,
    method: "DELETE",
    data,
  });
}
export default instance;
