/*
 * @Description:
 * @Autor: shh
 * @Date: 2022-07-28 11:23:25
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-15 10:17:54
 */
import Vue from "vue";
import ProTable from "@/components/ProTable/index.vue";
import ProDialog from "@/components/ProDialog/index.vue";
import EasyDialog from "@/components/ProDialog/EasyDialog.vue";
import ProDrawer from "@/components/ProDrawer/index.vue";
import ProForm from "@/components/ProForm/index.vue";
Vue.component("ProTable", ProTable);
Vue.component("ProDialog", ProDialog);
Vue.component("EasyDialog", EasyDialog);
Vue.component("ProDrawer", ProDrawer);
Vue.component("ProForm", ProForm);
