/*
 * @Description: 
 * @Autor: shh
 * @Date: 2022-11-25 14:34:00
 * @LastEditors: shh
 * @LastEditTime: 2023-03-28 22:42:22
 */
import Cookies from 'js-cookie'

const state = {
  sidebar: [],
  // sidebar: {
  //   opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
  //   withoutAnimation: false
  // },
  nav: '',
  device: 'desktop'
}

const mutations = {

  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_NAV: (state, nav) => {
    state.nav = nav
  },
  SET_SIDEBAR: (state, sidebar) => {
    state.sidebar = sidebar
  },
}

const actions = {
  setSlider({ commit }, data) {
    localStorage.setItem('menu', JSON.stringify(data))
    commit('SET_NAV', data.name)
    commit('SET_SIDEBAR', data.children)
  },
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
