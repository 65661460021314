/*
 * @Description:
 * @Autor: shh
 * @Date: 2022-10-14 15:08:04
 * @LastEditors:
 * @LastEditTime: 2023-12-15 10:05:15
 */
import router from "../router";
import store from "../store";
import { Message, Notification } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken } from "@/utils/auth";
import getPageTitle from "@/utils/get-page-title";
NProgress.configure({ showSpinner: false });
const whiteList = ["/login"];
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  document.title = getPageTitle(to.meta.title);
  const hasToken = getToken();
  if (hasToken) {
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      next();

      const hasGetUserInfo = JSON.parse(
        localStorage.getItem("userInfo")
      )?.username;
      if (hasGetUserInfo) {
        next();
      } else {
        await store.dispatch("user/resetToken");
        next(`/login?redirect=${to.path}`);
        NProgress.done();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});
router.afterEach(() => {
  NProgress.done();
});
