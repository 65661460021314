/*
 * @Description:
 * @Autor: shh
 * @Date: 2022-04-17 19:20:26
 * @LastEditors: shh
 * @LastEditTime: 2023-11-10 16:35:52
 */
import axios from "axios";
import { get, post, put, del } from "@/utils/request";
const power = localStorage.getItem("power");
const base = power;
// =========门店管理===========
export const shopListApi = (data) => get(base + "/v1/manage/store/list", data); // 分页查询
export const shopAddApi = (data) => post(base + "/v1/manage/store/", data); // 新增
export const shopEditApi = (data) =>
  put(base + "/v1/manage/store/basicInfo", data); // 修改
export const shopDelApi = (id) => del(base + `/v1/manage/store/${[id]}`); // 删除
export const shopInfoApi = (id) => get(base + `/v1/manage/store/${id}`); // 详情
export const shopDisabledApi = (id) =>
  put(base + `/v1/manage/store/disable/${id}`); // 停用
export const shopEnabledApi = (id) =>
  put(base + `/v1/manage/store/enable/${id}`); // 启用
export const shopMemberAddApi = (data) =>
  put(base + `/v1/manage/staff/changeStore`, data); // 批量设置/移除门店员工
export const storeCheckApi = (id) =>
  put(base + `/v1/manage/store/approved//${id}`); // 审核

export const agentListApi = (data) =>
  get(base + "/v1/manage/staff/list", {
    pageNo: 1,
    pageSize: 10000,
    ...data,
  }); // 所有门店经纪人
export const agentAllApi = () =>
  get(base + "/v1/manage/staff/list", {
    pageNo: 1,
    pageSize: 10000,
    storeId: "-1",
    approved: 1,
  }); // 所有无门店的店员

// =========经纪人管理===========
export const shopAgentApi = (data) => get(base + "/v1/manage/staff/list", data); // 分页查询
export const shopAgentAddApi = (data) => post(base + "/v1/manage/staff/", data); // 新增
export const shopAgentEditApi = (data) =>
  put(base + "/v1/manage/staff/basicInfo", data); // 编辑
export const shopAgentInfoApi = (id) => get(base + `/v1/manage/staff/${id}`); // 详情
export const shopAgentremApi = (data) =>
  put(base + `/v1/manage/staff/changeStore`, data); // 批量设置/移除门店员工
