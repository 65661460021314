import Vue from "vue";

Vue.directive("drag", {
  bind(el, binding, vnode) {
    const dialogHeaderEl = el.querySelector(".el-dialog__header");
    const dragDom = el.querySelector(".el-dialog");
    dialogHeaderEl.style.cssText += ";cursor:move;";
    dragDom.style.cssText += ";top:0px;";

    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const getStyle = (function () {
      if (window.document.currentStyle) {
        return (dom, attr) => dom.currentStyle[attr];
      } else {
        return (dom, attr) => getComputedStyle(dom, false)[attr];
      }
    })();

    dialogHeaderEl.onmousedown = (e) => {
      const disX = e.clientX - dialogHeaderEl.offsetLeft;
      const disY = e.clientY - dialogHeaderEl.offsetTop;

      const dragDomWidth = dragDom.offsetWidth;
      const dragDomHeight = dragDom.offsetHeight;

      const screenWidth = document.body.clientWidth;
      const screenHeight = document.body.clientHeight;

      const minDragDomLeft = dragDom.offsetLeft;
      const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth;

      const minDragDomTop = dragDom.offsetTop;
      const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomHeight;

      let styL = getStyle(dragDom, "left");
      let styT = getStyle(dragDom, "top");

      if (styL.includes("%")) {
        styL = +document.body.clientWidth * (+styL.replace(/\%/g, "") / 100);
        styT = +document.body.clientHeight * (+styT.replace(/\%/g, "") / 100);
      } else {
        styL = +styL.replace(/\px/g, "");
        styT = +styT.replace(/\px/g, "");
      }

      document.onmousemove = function (e) {
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        if (-left > minDragDomLeft) {
          left = -minDragDomLeft;
        } else if (left > maxDragDomLeft) {
          left = maxDragDomLeft;
        }

        if (-top > minDragDomTop) {
          top = -minDragDomTop;
        } else if (top > maxDragDomTop) {
          top = maxDragDomTop;
        }

        dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`;

        vnode.child.$emit("dragDialog");
      };

      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  },
});

Vue.directive("dialogDrag", {
  bind(el, binding, vnode, oldVnode) {
    if (binding.value) {
      const dialogHeaderEl = el.querySelector(".dialog_header");
      dialogHeaderEl.style.cursor = "move";
      dialogHeaderEl.onmousedown = function (e) {
        var x = e.clientX - el.offsetLeft;
        var y = e.clientY - el.offsetTop;
        document.onmousemove = function (eve) {
          el.style.left = eve.clientX - x + "px";
          el.style.top = eve.clientY - y + "px";
        };
        document.onmouseup = function () {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };
    }
  },
});

Vue.directive("dialogZoom", {
  bind(el, binding, vnode, oldVnode) {
    setTimeout(() => {
      if (binding.value) {
        const dragDom = el.parentNode.parentNode;
        el.style.cursor = "se-resize";
        el.onmousedown = (e) => {
          const mask = document.createElement("div");
          mask.setAttribute(
            "style",
            "position:fixed;top:0px;bottom:0px;left:0px;right:0px;background:rgba(0,0,0,0)"
          );
          document.body.appendChild(mask);
          const disX = e.clientX - el.offsetLeft;
          const disY = e.clientY - el.offsetTop;
          document.body.onmousemove = function (e) {
            e.preventDefault();

            const l = e.clientX - disX;
            const h = e.clientY - disY;
            dragDom.style.width = `${l}px`;
            dragDom.style.height = `${
              h > document.body.offsetHeight ? document.body.offsetHeight : h
            }px`;
          };
          document.body.onmouseup = function (e) {
            document.body.removeChild(mask);
            document.body.onmousemove = null;
            document.body.onmouseup = null;
          };
        };
      }
    }, 400);
  },
});
