/*
 * @Description:
 * @Autor: shh
 * @Date: 2022-11-25 14:34:00
 * @LastEditors: shh
 * @LastEditTime: 2023-03-24 16:37:56
 */
import defaultSettings from "@/config/setting";

const { showSettings, fixedHeader, sidebarLogo } = defaultSettings;

const state = {
  showSettings: showSettings,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
};

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
};

const actions = {
  setSlider({ commit }, data) {
    commit("SET_NAME", "22222");
    commit("SET_SIDEBAR", data);
  },
  changeSetting({ commit }, data) {
    commit("CHANGE_SETTING", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
