/*
 * @Description:
 * @Autor: shh
 * @Date: 2023-03-30 22:32:55
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2024-01-17 17:27:39
 */
export function dictName(type) {
  const res = dictData.filter((items) => items["value"] === type);
  return res[0].options;
}

// 房源字典
export const dictData = [
  {
    label: "是否",
    value: "SF",
    desc: "",
    options: [
      {
        label: "是",
        value: 1,
      },
      {
        label: "否",
        value: 0,
      },
    ],
  },
  {
    label: "售卖状态",
    value: "SMZT",
    desc: "",
    options: [
      {
        label: "待售",
        value: 0,
      },
      {
        label: "在售",
        value: 1,
      },
      {
        label: "售罄",
        value: 2,
      },
    ],
  },

  {
    label: "装修情况",
    value: "ZXQK",
    desc: "",
    options: [
      {
        label: "精装",
        value: 1,
      },
      {
        label: "简装",
        value: 2,
      },
      {
        label: "豪装",
        value: 3,
      },
      {
        label: "毛坯",
        value: 4,
      },
    ],
  },
  {
    label: "户型",
    value: "HX",
    desc: "",
    options: [
      {
        label: "一室",
        value: 1,
      },
      {
        label: "二室",
        value: 2,
      },
      {
        label: "三室",
        value: 3,
      },
      {
        label: "四室",
        value: 4,
      },
      {
        label: "五室",
        value: 5,
      },
      {
        label: "五室以上",
        value: 6,
      },
    ],
  },
  {
    label: "楼盘特色",
    value: "LPTS",
    desc: "",
    options: [
      {
        label: "绿化率高",
        value: "1",
      },
      {
        label: "优惠楼盘",
        value: "2",
      },
      {
        label: "品牌房企",
        value: "3",
      },
      {
        label: "小户型",
        value: "4",
      },
      {
        label: "大户型",
        value: "5",
      },
      {
        label: "现房",
        value: "6",
      },
      {
        label: "低单价",
        value: "7",
      },
      {
        label: "交通便利",
        value: "8",
      },
    ],
  },
  {
    label: "户型特色",
    value: "HXTS",
    desc: "",
    options: [
      {
        label: "满2年",
        value: "1",
      },
      {
        label: "满5年",
        value: "2",
      },
      {
        label: "全明格局",
        value: "3",
      },
      {
        label: "全南户型",
        value: "4",
      },
      {
        label: "户型方正",
        value: "5",
      },
      {
        label: "公摊面积小",
        value: "6",
      },
      {
        label: "南北通透",
        value: "7",
      },

      {
        label: "厅带阳台",
        value: "8",
      },

      {
        label: "卧室带卫",
        value: "9",
      },
      {
        label: "明厨明卫",
        value: "10",
      },
    ],
  },
  {
    label: "房屋特色",
    value: "ZFTS",
    desc: "",
    options: [
      {
        label: "支持短租",
        value: "1",
      },
      {
        label: "随时入住",
        value: "2",
      },
      {
        label: "全明格局",
        value: "3",
      },
      {
        label: "全南户型",
        value: "4",
      },
      {
        label: "户型方正",
        value: "5",
      },
      {
        label: "无服务费",
        value: "6",
      },
    ],
  },
  {
    label: "房屋朝向",
    value: "FWCX",
    desc: "",
    options: [
      {
        label: "南北",
        value: "1",
      },
      {
        label: "东西",
        value: "6",
      },
      {
        label: "朝南",
        value: "2",
      },
      {
        label: "朝东",
        value: "3",
      },
      {
        label: "朝北",
        value: "4",
      },
      {
        label: "朝西",
        value: "5",
      },
    ],
  },
  {
    label: "建筑面积",
    value: "JZMJ",
    desc: "",
    options: [
      {
        label: "50m²",
        value: "0-50",
      },
      {
        label: "50-70m²",
        value: "50-70",
      },
      {
        label: "70-90m²",
        value: "70-90",
      },
      {
        label: "90-110m²",
        value: "90-110",
      },
      {
        label: "110-130m²",
        value: "110-130",
      },
      {
        label: "130-150m²",
        value: "130-150",
      },
      {
        label: "150-200m²",
        value: "150-200",
      },
      {
        label: "200-300m²",
        value: "200-300",
      },
      {
        label: "300m²以上",
        value: ">300",
      },
    ],
  },
  // 商铺 写字楼 土地 厂房 仓库 56789（首页入口找商业）
  {
    label: "房屋类型",
    value: "FWLX",
    desc: "",
    options: [
      {
        label: "住宅",
        value: 1,
      },
      {
        label: "公寓",
        value: 2,
      },
      {
        label: "别墅",
        value: 3,
      },
      {
        label: "车位",
        value: 4,
      },
      {
        label: "商铺",
        value: 5,
      },
      {
        label: "写字楼",
        value: 6,
      },
      {
        label: "厂房",
        value: 7,
      },
      {
        label: "仓库",
        value: 8,
      },
      {
        label: "土地",
        value: 9,
      },
    ],
  },
  {
    label: "建筑类型",
    value: "JZLX",
    desc: "",
    options: [
      {
        label: "多层",
        value: 1,
      },
      {
        label: "高层",
        value: 2,
      },
      {
        label: "小高层",
        value: 3,
      },
    ],
  },
  {
    label: "所在楼层",
    value: "SZLC",
    desc: "",
    options: [
      {
        label: "低楼层",
        value: 1,
      },
      {
        label: "中楼层",
        value: 2,
      },
      {
        label: "高楼层",
        value: 3,
      },
    ],
  },
  {
    label: "供暖方式",
    value: "GNLX",
    desc: "",
    options: [
      {
        label: "集体供暖",
        value: "1",
      },
      {
        label: "自采暖",
        value: "2",
      },
      {
        label: "无供暖",
        value: "3",
      },
    ],
  },
  {
    label: "产权类别",
    value: "CQLB",
    desc: "",
    options: [
      {
        label: "商品房",
        value: 1,
      },
      {
        label: "公房",
        value: 2,
      },
      {
        label: "经适房",
        value: 3,
      },
      {
        label: "廉租房",
        value: 4,
      },
      {
        label: "小产权房",
        value: 5,
      },
    ],
  },

  {
    label: "产权所属",
    value: "CQSS",
    desc: "",
    options: [
      {
        label: "共有",
        value: 1,
      },
      {
        label: "非共有",
        value: 2,
      },
    ],
  },
  {
    label: "产权年限",
    value: "CQNX",
    desc: "",
    options: [
      {
        label: "40年",
        value: 1,
      },
      {
        label: "50年",
        value: 2,
      },
      {
        label: "70年",
        value: 3,
      },
    ],
  },
  {
    label: "供水类型",
    value: "YSLX",
    desc: "",
    options: [
      {
        label: "民水",
        value: "1",
      },
      {
        label: "商水",
        value: "2",
      },
    ],
  },
  {
    label: "供电类型",
    value: "YDLX",
    desc: "",
    options: [
      {
        label: "民电",
        value: "1",
      },
      {
        label: "商电",
        value: "2",
      },
    ],
  },
  {
    label: "楼龄",
    value: "LL",
    desc: "",
    options: [
      {
        label: "5年以内",
        value: 1,
      },
      {
        label: "10年以内",
        value: 2,
      },
      {
        label: "15年以内",
        value: 3,
      },
      {
        label: "20年以内",
        value: 4,
      },
      {
        label: "20年以上",
        value: 5,
      },
    ],
  },
  {
    label: "电梯",
    value: "DT",
    desc: "",
    options: [
      {
        label: "有电梯",
        value: "1",
      },
      {
        label: "无电梯",
        value: "0",
      },
    ],
  },
  {
    label: "销售状态",
    value: "XSZT",
    desc: "",
    options: [
      {
        label: "在售",
        value: 1,
      },
      {
        label: "待售",
        value: 2,
      },
      {
        label: "售罄",
        value: 3,
      },
    ],
  },
  {
    label: "房屋用途",
    value: "FWYT",
    desc: "",
    options: [
      {
        label: "普通住宅",
        value: 1,
      },
      {
        label: "商业类",
        value: 2,
      },
      {
        label: "别墅",
        value: 3,
      },
      {
        label: "四合院",
        value: 4,
      },
      {
        label: "车位",
        value: 5,
      },
      {
        label: "其他",
        value: 6,
      },
    ],
  },
  {
    label: "物业类型",
    value: "WYLX",
    desc: "",
    options: [
      {
        label: "住宅",
        value: 1,
      },
      {
        label: "商品房",
        value: 2,
      },
    ],
  },
  {
    label: "出租类型",
    value: "CZLX",
    desc: "",
    options: [
      {
        label: "整租",
        value: 1,
      },
      {
        label: "合租",
        value: 2,
      },
    ],
  },

  {
    label: "房屋设施",
    value: "FWSS",
    desc: "",
    options: [
      {
        label: "冰箱",
        value: "1",
      },
      {
        label: "洗衣机",
        value: "2",
      },
      {
        label: "热水器",
        value: "3",
      },
      {
        label: "宽带",
        value: "4",
      },
      {
        label: "沙发",
        value: "5",
      },
      {
        label: "抽烟机",
        value: "6",
      },
      {
        label: "燃气灶",
        value: "7",
      },
      {
        label: "电视",
        value: "8",
      },
      {
        label: "空调",
        value: "9",
      },
      {
        label: "衣柜",
        value: "10",
      },
      {
        label: "床",
        value: "11",
      },
      {
        label: "餐桌",
        value: "12",
      },
      {
        label: "书桌",
        value: "13",
      },
      {
        label: "暖气",
        value: "15",
      },
      {
        label: "电磁炉",
        value: "16",
      },
    ],
  },
];
//性别
export const sexDict = [
  {
    label: "男",
    value: 1,
  },
  {
    label: "女",
    value: 2,
  },
];
//发布类型
export const pubDict = [
  {
    label: "出售",
    value: 1,
  },
  {
    label: "求购",
    value: 2,
  },
  {
    label: "出租",
    value: 3,
  },
  {
    label: "求租",
    value: 4,
  },
];
// 角色
export const roleDict = [
  {
    label: "店长",
    value: "1",
  },
  {
    label: "内部经纪人",
    value: "2",
  },
  {
    label: "独立经纪人",
    value: "3",
  },
  {
    label: "三边经纪人",
    value: "4",
  },
];
export const roleDict2 = [
  {
    label: "内部经纪人",
    value: "2",
  },
  {
    label: "独立经纪人",
    value: "3",
  },
  {
    label: "三边经纪人",
    value: "4",
  },
];

//区域
export const areaDict = [
  {
    label: "旌阳区",
    value: "510603",
    children: [
      // 片区
      {
        value: "旌阳区-旌阳区",
        label: "旌阳区",
      },
      {
        value: "旌阳区-城北片区",
        label: "城北片区",
      },
      {
        value: "旌阳区-城南片区",
        label: "城南片区",
      },
      {
        value: "旌阳区-旌东",
        label: "旌东",
      },
      {
        value: "旌阳区-玉泉片区",
        label: "玉泉片区",
      },
      {
        value: "旌阳区-黄河片区",
        label: "黄河片区",
      },
      {
        value: "旌阳区-高铁新区",
        label: "高铁新区",
      },
      {
        value: "旌阳区-工农",
        label: "工农",
      },
      {
        value: "旌阳区-八角井",
        label: "八角井",
      },
      // 商业中心
      {
        value: "旌阳区-鼎虹市场",
        label: "鼎虹市场",
      },
      {
        value: "旌阳区-玉泉市场",
        label: "玉泉市场",
      },
      {
        value: "旌阳区-电信广场",
        label: "电信广场",
      },
      {
        value: "旌阳区-五洲广场",
        label: "五洲广场",
      },
      {
        value: "旌阳区-沃尔玛",
        label: "沃尔玛",
      },
      {
        value: "旌阳区-洋洋百货",
        label: "洋洋百货",
      },
      {
        value: "旌阳区-万达广场",
        label: "万达广场",
      },
      {
        value: "旌阳区-文庙广场",
        label: "文庙广场",
      },
      {
        value: "旌阳区-石刻",
        label: "石刻",
      },
      //生活圈
      {
        value: "旌阳区-彩泉",
        label: "彩泉",
      },
      {
        value: "旌阳区-政务中心",
        label: "政务中心",
      },
      {
        value: "旌阳区-102生活广场",
        label: "102生活广场",
      },
      {
        value: "旌阳区-东电生活区",
        label: "东电生活区",
      },
      {
        value: "旌阳区-德什路口",
        label: "德什路口",
      },
      {
        value: "旌阳区-柳梢堰",
        label: "柳梢堰",
      },
      {
        value: "旌阳区-皇冠灯",
        label: "皇冠灯",
      },
      //街道
      {
        value: "旌阳区-庐山北路",
        label: "庐山北路",
      },
      {
        value: "旌阳区-泰山北路",
        label: "泰山北路",
      },
      {
        value: "旌阳区-黄河东路",
        label: "黄河东路",
      },
      {
        value: "旌阳区-华山南路",
        label: "华山南路",
      },
      //乡镇
      {
        value: "旌阳区-黄许镇",
        label: "黄许镇",
      },
      {
        value: "旌阳区-孝感镇",
        label: "孝感镇",
      },
      {
        value: "旌阳区-柏隆镇",
        label: "柏隆镇",
      },
      {
        value: "旌阳区-天元镇",
        label: "天元镇",
      },
      {
        value: "旌阳区-德新镇",
        label: "德新镇",
      },
      {
        value: "旌阳区-孝泉镇",
        label: "孝泉镇",
      },
      {
        value: "旌阳区-寿丰镇",
        label: "寿丰镇",
      },
      {
        value: "旌阳区-和新镇",
        label: "和新镇",
      },
      {
        value: "旌阳区-杨嘉镇",
        label: "杨嘉镇",
      },

      //学校
      {
        value: "旌阳区-市一小",
        label: "市一小",
      },
      {
        value: "旌阳区-实验小学",
        label: "实验小学",
      },
      {
        value: "旌阳区-庐山路小学",
        label: "庐山路小学",
      },
      {
        value: "旌阳区-岷山路小学",
        label: "岷山路小学",
      },
      {
        value: "旌阳区-珠江路小学",
        label: "珠江路小学",
      },
      {
        value: "旌阳区-青衣江路小学",
        label: "青衣江路小学",
      },
      {
        value: "旌阳区-东电外国语小学",
        label: "东电外国语小学",
      },
    ],
  },
  {
    label: "罗江区",
    value: "510604",
    children: [
      {
        value: "罗江区-罗江",
        label: "罗江",
      },
      {
        value: "罗江区-城东",
        label: "城东",
      },
      {
        value: "罗江区-城西",
        label: "城西",
      },
      {
        value: "罗江区-城南",
        label: "城南",
      },
      {
        value: "罗江区-城北",
        label: "城北",
      },
      {
        value: "罗江区-城中",
        label: "城中",
      },
      {
        value: "罗江区-雒城镇",
        label: "雒城镇",
      },
    ],
  },
  {
    label: "中江县",
    value: "510623",
    children: [
      {
        value: "中江县-中江",
        label: "中江",
      },
      {
        value: "中江县-城东",
        label: "城东",
      },
      {
        value: "中江县-城东",
        label: "城西",
      },
      {
        value: "中江县-城南",
        label: "城南",
      },
      {
        value: "中江县-城北",
        label: "城北",
      },
      {
        value: "中江县-城中",
        label: "城中",
      },
      {
        value: "中江县-凯江镇",
        label: "凯江镇",
      },
    ],
  },
  {
    label: "广汉市",
    value: "510681",
    children: [
      {
        value: "广汉市-广汉",
        label: "广汉",
      },
      {
        value: "广汉市-城东",
        label: "城东",
      },
      {
        value: "广汉市-城西",
        label: "城西",
      },
      {
        value: "广汉市-城南",
        label: "城南",
      },
      {
        value: "广汉市-城北",
        label: "城北",
      },
      {
        value: "广汉市-城中",
        label: "城中",
      },
      {
        value: "广汉市-万安镇",
        label: "万安镇",
      },
    ],
  },
  {
    label: "什邡市",
    value: "510682",
    children: [
      {
        value: "什邡市-什邡",
        label: "什邡",
      },
      {
        value: "什邡市-城东",
        label: "城东",
      },
      {
        value: "什邡市-城西",
        label: "城西",
      },
      {
        value: "什邡市-城南",
        label: "城南",
      },
      {
        value: "什邡市-城北",
        label: "城北",
      },
      {
        value: "什邡市-城中",
        label: "城中",
      },
      {
        value: "什邡市-方亭街道",
        label: "方亭街道",
      },
    ],
  },
  {
    label: "绵竹市",
    value: "510683",
    children: [
      {
        value: "绵竹市-绵竹",
        label: "绵竹",
      },
      {
        value: "绵竹市-城东",
        label: "城东",
      },
      {
        value: "绵竹市-城西",
        label: "城西",
      },
      {
        value: "绵竹市-城南",
        label: "城南",
      },
      {
        value: "绵竹市-城北",
        label: "城北",
      },
      {
        value: "绵竹市-城中",
        label: "城中",
      },
      {
        value: "绵竹市-剑南镇",
        label: "剑南镇",
      },
    ],
  },
];
