<!--
 * @Description: 
 * @Autor: shh
 * @Date: 2022-07-28 09:42:45
 * @LastEditors: shh
 * @LastEditTime: 2022-07-29 12:03:02
-->
<!-- table-setting -->
<template>
  <div class="table-setting">
    <i class="el-icon-refresh" @click="refresh" />
    <i class="el-icon-full-screen" @click="fullscreen" />
  </div>
</template>

<script>
import { getScrollBarWidth } from "@/utils";
import { toggleClass, hasClass } from "@/utils";
const scrollBarWidth = getScrollBarWidth();
export default {
  name: "TableSetting",
  props: {
    refresh: {
      type: Function,
      default: () => {},
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    // draggableList() {
    //   get: () => prop.columns,
    //         set: (val) => {
    //           emit('update:columns', val)
    //           emit('reRender')
    //         },
    // }
  },
  methods: {
    fullscreen() {
      const el = document.querySelector("#app .app-container");
      toggleClass(el, "content-screenfull");
      if (hasClass(el, "content-screenfull")) {
        document.documentElement.style.overflow = "hidden";
        document.body.style.borderRight =
          scrollBarWidth + "px solid transparent";
      } else {
        document.documentElement.style.overflow = "";
        document.body.style.borderRight = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.list-group-item {
  max-width: 80%;
  min-width: 40%;
  margin: 5px auto;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;

  span {
    vertical-align: middle;
  }

  .btnWrap {
    display: flex;
    align-items: center;
  }
}
</style>
