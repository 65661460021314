<!--
 * @Description: 地图弹窗
 * @Autor: shh
 * @Date: 2022-07-20 15:09:00
 * @LastEditors: shh
 * @LastEditTime: 2023-03-17 16:58:50
-->
<template>
  <div v-dialogDrag="drag" :style="styleClass" class="dialog-box">
    <div class="dialog_header">
      <div class="title">{{ title }}</div>
      <div class="icon" @click="close">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="cont">
      <slot />
      <div v-dialogZoom="zoom" class="zoom"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    styleClass: {
      type: Object,
      default: () => {
        return {};
      },
    },
    drag: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-box {
  background: #fff;
  background-size: 100% 100%;
  margin: 0 auto;
  padding: 20px 30px;
  box-shadow: none;
  background: transparent;
  background-image: url("@/assets/images/lng/dialog.png");
  background-size: 100% 100%;
  position: absolute;
  z-index: 800;
  .dialog_header {
    width: 100%;
    height: 30px;
    padding: 0;
    background: linear-gradient(90deg, #7ca1ea 0%, rgba(69, 125, 236, 0) 50%);

    .title {
      line-height: 30px;
      width: 50%;
      padding: 0px 10px;
      position: relative;
      font-size: 18px;
      font-family: ShiShangZhongHeiJianTi;
      letter-spacing: 2px;
      font-weight: 800;
      color: #284c97;
      text-align: left;
      &::before {
        content: "";
        width: 4px;
        height: 30px;
        background: linear-gradient(90deg, #457dec 0%, #3e7bfa 100%);
        display: inline-block;
        position: absolute;
        left: -7px;
        // transform: translate(0, -50%);
        top: 0;
      }
    }
    .icon {
      background-size: 100% 100%;
      width: 15px;
      height: 15px;
      position: absolute;
      right: 25px;
      top: 20px;
      cursor: pointer;
    }
  }
  .cont {
    width: 100%;
    height: calc(100% - 60px);
  }
  .zoom {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>
