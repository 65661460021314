/*
 * @Description:
 * @Autor: shh
 * @Date: 2022-07-04 13:41:16
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-18 10:34:45
 */
import Vue from "vue";
import Router from "vue-router";
import LayoutSystem from "@/layout/layout-system.vue";

Vue.use(Router);
export const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/Banner",
    name: "Banner",
    component: () => import("@/views/applet/Banner"),
    meta: {
      title: "测试页",
    },
  },
  {
    path: "/",
    component: LayoutSystem,
    name: "admin",
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        power: ["admin"],
        component: () => import("@/views/Home"),
        meta: {
          title: "首页",
          icon: "menu",
        },
      },
      {
        path: "/system",
        name: "System",
        redirect: "/system/menu",
        component: () => import("@/views/Load"),
        power: ["admin"],
        meta: {
          title: "系统管理",
          icon: "s-tools",
        },
        children: [
          {
            path: "/system/admin",
            name: "Admin",
            component: () => import("@/views/system/Admin"),
            meta: {
              title: "系统管理员",
            },
          },

          {
            path: "/system/member",
            name: "Member",
            component: () => import("@/views/system/Member"),
            meta: {
              title: "经纪人管理",
            },
          },
          {
            path: "/system/shop",
            name: "Shop",
            component: () => import("@/views/system/Shop"),
            meta: {
              title: "门店管理",
            },
          },
          {
            path: "/system/role",
            name: "Role",
            component: () => import("@/views/system/Role"),
            meta: {
              title: "角色管理",
            },
          },
          {
            path: "/system/dict",
            name: "Dict",
            component: () => import("@/views/system/Dict"),
            meta: {
              title: "房产字典",
            },
          },
          {
            path: "/system/setting",
            name: "Setting",
            component: () => import("@/views/system/Setting1"),
            meta: {
              title: "平台设置",
            },
          },
          {
            path: "/system/settingRoot",
            name: "Setting",
            hidden: true,
            component: () => import("@/views/system/Setting"),
            meta: {
              title: "平台设置2",
            },
          },
        ],
      },
      {
        path: "Applet",
        name: "Applet",
        component: () => import("@/views/Load"),
        power: ["admin"],
        meta: {
          title: "客户端管理",
          icon: "menu",
        },
        children: [
          {
            path: "/applet/Index",
            name: "Index",
            component: () => import("@/views/applet/Index"),
            meta: {
              title: "推广内容管理",
            },
          },
          {
            path: "/applet/notice",
            name: "Notice",
            component: () => import("@/views/applet/Notice"),
            meta: {
              title: "通知公告",
            },
          },

          // {
          //   path: '/Hotagent',
          //   name: 'Hotagent',
          //   component: () => import('@/views/applet/Hotagent'),
          //   meta: {
          //     title: '金牌经纪人',
          //   }
          // }
        ],
      },

      {
        path: "/house",
        name: "House",
        power: ["admin"],
        redirect: "/house/house",
        component: () => import("@/views/Load"),
        meta: {
          title: "房源管理",
          icon: "camera-solid",
        },
        children: [
          {
            path: "/house/village",
            name: "Village",
            component: () => import("@/views/house/Village"),
            meta: {
              title: "小区字典",
            },
          },
          {
            path: "/house/build",
            name: "Build",
            component: () => import("@/views/house/Build"),
            meta: {
              title: "新盘管理",
            },
          },
          {
            path: "/house/home",
            name: "Home",
            component: () => import("@/views/house/Home"),
            meta: {
              title: "二房管理",
            },
          },
          {
            path: "/house/rent",
            name: "Rent",
            component: () => import("@/views/house/Rent"),
            meta: {
              title: "租房管理",
            },
          },
          // {
          //   path: '/house/look',
          //   name: 'Look',
          //   component: () => import('@/views/house/Look'),
          //   meta: {
          //     title: '带看记录',
          //   }
          // },
          // {
          //   path: '/house/survey',
          //   name: 'Survey',
          //   component: () => import('@/views/house/Survey'),
          //   meta: {
          //     title: '实勘记录',
          //   }
          // },
          // {
          //   path: '/house/key',
          //   name: 'Key',
          //   component: () => import('@/views/house/Key'),
          //   meta: {
          //     title: '钥匙管理',
          //   }
          // }
        ],
      },
      {
        path: "/custom",
        name: "Custom",
        power: ["admin"],
        component: () => import("@/views/Load"),
        meta: {
          title: "客源管理",
          icon: "s-tools",
        },
        children: [
          {
            path: "/system/User",
            name: "User",
            component: () => import("@/views/system/User"),
            meta: {
              title: "用户管理",
            },
          },
          {
            path: "/custom/publish",
            name: "Publish",
            component: () => import("@/views/custom/Publish"),
            meta: {
              title: "委托记录",
            },
          },
          {
            path: "/custom/purposes",
            name: "Purposes",
            component: () => import("@/views/custom/PurposeList"),
            meta: {
              title: "需求记录",
            },
          },
        ],
      },
      {
        path: "/check",
        name: "Check",
        power: ["admin"],
        component: () => import("@/views/Load"),
        meta: {
          title: "审批处理",
          icon: "s-tools",
        },
        children: [
          {
            path: "/check/agent",
            name: "Cagent",
            component: () => import("@/views/check/Agent"),
            meta: {
              title: "经纪人入驻",
            },
          },
          {
            path: "/check/shop",
            name: "Cshop",
            component: () => import("@/views/check/Shop"),
            meta: {
              title: "门店入驻",
            },
          },
          // , {
          //   path: '/check/house',
          //   name: 'Chouse',
          //   component: () => import('@/views/check/House'),
          //   meta: {
          //     title: '房源发布',
          //   }
          // }
        ],
      },
      // {
      //   path: '/sign',
      //   name: 'Sign',
      //   power: ['admin'],
      //   component: () => import('@/views/Load'),
      //   meta: {
      //     title: '成交记录',
      //     icon: 's-tools'
      //   }, children: [
      //     {
      //       path: '/sign/house',
      //       name: 'Shouse',
      //       component: () => import('@/views/sign/House'),
      //       meta: {
      //         title: '成交房源',
      //       }
      //     },
      //     //  {
      //     //   path: '/sign/user',
      //     //   name: 'Suser',
      //     //   component: () => import('@/views/sign/User'),
      //     //   meta: {
      //     //     title: '成交用户',
      //     //   }
      //     // },
      //     {
      //       path: '/sign/merits',
      //       name: 'Merits',
      //       component: () => import('@/views/sign/Merits'),
      //       meta: {
      //         title: '绩效管理',
      //       }
      //     }
      //   ]
      // },
      // {
      //   path: '/means',
      //   name: 'Means',
      //   power: ['admin'],
      //   component: () => import('@/views/Load'),
      //   meta: {
      //     title: '合同资料',
      //     icon: 's-tools'
      //   }, children: [
      //     {
      //       path: '/means/contract',
      //       name: 'Contract',
      //       component: () => import('@/views/means/Contract'),
      //       meta: {
      //         title: '合同模版',
      //       }
      //     }, {
      //       path: '/means/rules',
      //       name: 'Rules',
      //       component: () => import('@/views/means/Rules'),
      //       meta: {
      //         title: '规章制度',
      //       }
      //     }, {
      //       path: '/means/script',
      //       name: 'Script',
      //       component: () => import('@/views/means/Script'),
      //       meta: {
      //         title: '话术资料',
      //       }
      //     }
      //   ]
      // },
    ],
  },
  {
    path: "/",
    component: LayoutSystem,
    name: "staff",
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        power: ["staffManager", "staff"],
        component: () => import("@/views/Home"),
        meta: {
          title: "首页",
          icon: "menu",
        },
      },
      {
        path: "/store",
        name: "Store",
        power: ["staffManager"],
        redirect: "/store/shop",
        component: () => import("@/views/Load"),
        meta: {
          title: "门店体系",
          icon: "menu",
        },
        children: [
          {
            path: "/store/shop",
            name: "Shop",
            component: () => import("@/views/store/Shop"),
            meta: {
              title: "门店管理",
            },
          },
          {
            path: "/store/agent",
            name: "Agent",
            component: () => import("@/views/store/Agent"),
            meta: {
              title: "门店经纪人",
            },
          },
        ],
      },

      {
        path: "/house",
        name: "House",
        power: ["staffManager", "staff"],

        redirect: "/house/house",
        component: () => import("@/views/Load"),
        meta: {
          title: "房源管理",
          icon: "camera-solid",
        },
        children: [
          {
            path: "/house/village",
            name: "Village",
            component: () => import("@/views/house/Village"),
            meta: {
              title: "小区字典",
            },
          },
          {
            path: "/house/home",
            name: "Home",
            component: () => import("@/views/house/Home"),
            meta: {
              title: "二房管理",
            },
          },
          {
            path: "/house/rent",
            name: "Rent",
            // norole: "4",
            component: () => import("@/views/house/Rent"),
            meta: {
              title: "租房管理",
            },
          },
          // {
          //   path: '/house/look',
          //   name: 'Look',
          //   component: () => import('@/views/house/Look'),
          //   meta: {
          //     title: '带看记录',
          //   }
          // },
          // {
          //   path: '/house/map',
          //   name: 'Map',
          //   component: () => import('@/views/house/Map'),
          //   meta: {
          //     title: '房源地图',
          //   }
          // }
        ],
      },
      {
        path: "/custom",
        name: "Custom",
        // norole: "4",
        power: ["staffManager", "staff"],
        component: () => import("@/views/Load"),
        meta: {
          title: "工作台",
          icon: "s-tools",
        },
        children: [
          {
            path: "/house/build",
            name: "Build",
            component: () => import("@/views/house/Build"),
            meta: {
              title: "新盘资源",
            },
          },
          {
            path: "/work/group",
            name: "Survey",
            component: () => import("@/views/work/Group"),
            meta: {
              title: "新盘卖团",
            },
          },
          {
            path: "/custom/publish",
            name: "Publish",
            component: () => import("@/views/custom/Publish"),
            meta: {
              title: "委托抢单",
            },
          },
          {
            path: "/custom/pubclaim",
            name: "Pubclaim",
            component: () => import("@/views/custom/Pubclaim"),
            meta: {
              title: "委托任务",
            },
          },
          {
            path: "/custom/purpose",
            name: "Purpose",
            component: () => import("@/views/custom/Purpose"),
            meta: {
              title: "需求记录",
            },
          },
          // {
          //   path: '/custom/follow',
          //   name: 'Follow',
          //   component: () => import('@/views/custom/Follow'),
          //   meta: {
          //     title: '跟进记录',
          //   }
          // },
          // {
          //   path: '/house/survey',
          //   name: 'Survey',
          //   component: () => import('@/views/house/Survey'),
          //   meta: {
          //     title: '房勘管理',
          //   }
          // },
          // {
          //   path: '/house/survey',
          //   name: 'Survey',
          //   component: () => import('@/views/house/Survey'),
          //   meta: {
          //     title: '钥匙管理',
          //   }
          // },
        ],
      },
      // {
      //   path: '/sign',
      //   name: 'Sign',
      //   power: ['staffManager', 'staff'],
      //   component: () => import('@/views/Load'),
      //   meta: {
      //     title: '成交记录',
      //     icon: 's-tools'
      //   }, children: [
      //     {
      //       path: '/sign/house',
      //       name: 'Shouse',
      //       component: () => import('@/views/sign/House'),
      //       meta: {
      //         title: '成交房源',
      //       }
      //     }, {
      //       path: '/sign/merits',
      //       name: 'Merits',
      //       component: () => import('@/views/sign/Merits'),
      //       meta: {
      //         title: '我的绩效',
      //       }
      //     }
      //   ]
      // },
      // {
      //   path: '/means',
      //   name: 'Means',
      //   power: ['admin'],
      //   component: () => import('@/views/Load'),
      //   meta: {
      //     title: '合同资料',
      //     icon: 's-tools'
      //   }, children: [
      //     {
      //       path: '/means/contract',
      //       name: 'Contract',
      //       component: () => import('@/views/means/Contract'),
      //       meta: {
      //         title: '合同模版',
      //       }
      //     }, {
      //       path: '/means/rules',
      //       name: 'Rules',
      //       component: () => import('@/views/means/Rules'),
      //       meta: {
      //         title: '规章制度',
      //       }
      //     }, {
      //       path: '/means/script',
      //       name: 'Script',
      //       component: () => import('@/views/means/Script'),
      //       meta: {
      //         title: '话术资料',
      //       }
      //     }
      //   ]
      // },
    ],
  },
];
export const asyncRoutes = [];
const createRouter = () =>
  new Router({
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: constantRoutes,
  });
const router = createRouter();
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
