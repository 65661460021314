<!--
 * @Description: 
 * @Autor: shh
 * @Date: 2022-07-28 15:51:05
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-19 16:40:12
-->
<template>
  <div v-if="value" class="ProDialogWrap">
    <el-dialog
      ref="ProDialog"
      :loading="confirmLoading"
      :visible="value"
      v-bind="$attrs"
      :fullscreen="fullscreen || isFullscreen"
      :append-to-body="
        $attrs['append-to-body'] === undefined ? true : $attrs['append-to-body']
      "
      :close-on-click-modal="false"
      custom-class="ProDialog"
      @close="handleClose"
    >
      <!-- <span
        class="ProDialogFullscreen"
        @click="changeFullscreen"
        v-if="!fullscreen"
      >
         <i class="el-icon-full-screen"></i>
      </span> -->
      <template #title>
        <slot name="title"></slot>
      </template>
      <slot></slot>
      <template v-if="!noFooter" #footer>
        <span class="dialog-footer">
          <slot name="footer"></slot>
          <div class="btn-box">
            <el-button
              v-if="!noconfirm"
              :loading="confirmLoading"
              class="success-button"
              @click="handleOk"
            >
              {{ confirmText }}
            </el-button>
            <el-button
              :loading="confirmLoading"
              class="cancel-button m-l-40"
              @click="handleClose"
              >取消</el-button
            >
          </div>
          <!-- <el-button v-if="!noconfirm" type="primary" @click="handleOk"
            >{{ confirmText }}
          </el-button>
          <el-button @click="handleClose">关 闭</el-button> -->
        </span>
      </template>
      <!-- <template v-if="!noFooter" #footer>
        <slot v-if="$slots && $slots.footer" name="footer"></slot>
        <span v-else class="dialog-footer">
          <el-button @click="handleClose">关 闭</el-button>
          <el-button :loading="confirmLoading" type="primary" @click="handleOk">{{ confirmText }}</el-button>
        </span>
      </template> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ProDialog",
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    noconfirm: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: "保 存",
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    drag: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isFullscreen: false,
    };
  },
  computed: {
    // ...mapState({
    // 'routes': state => state.routes.routes
    // }),
    icon() {
      return this.isFullscreen ? "fullscreen-exit" : "fullscreen";
    },
  },
  created() {},
  // watch: {
  //   fullscreen: {
  //     handler: function () {
  //       if (attrs.fullscreen || attrs.fullscreen === '')
  //         this.isFullscreen = true
  //     },
  //     immediate: true
  //   },
  //   isFullscreen:{
  //      handler: function () {
  //       if (this.fullscreen || attrs.fullscreen === '')
  //         this.isFullscreen = true
  //     },
  //     immediate: true
  //   }
  // },

  // watchEffect(() => {
  //   if (isFullscreen.value) return
  //   if (props.value && ProDialog.value) {
  //     nextTick(() => {
  //       draggable(ProDialog.value.dialogRef)
  //     })
  //   }
  // })
  methods: {
    handleClose() {
      this.$emit("cancle");
      this.isFullscreen = false;
      // this.$emit('update:value', false)
    },
    handleOk() {
      this.$emit("ok");
      this.isFullscreen = false;
    },
    changeFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      // if (this.isFullscreen) {
      //   this.$refs.ProDialog.dialogRef.style.cssText += ';top:0px;left:0px;'
      // }
    },
    closeFullscreen() {
      this.isFullscreen = false;
      // if (this.isFullscreen) {
      //   this.$refs.ProDialog.dialogRef.style.cssText += ';top:0px;left:0px;'
      // }
    },
  },
  //  setup(props, { emit, attrs }) {
  // watch(
  //   () => attrs.fullscreen,
  //   () => {
  //     if (attrs.fullscreen || attrs.fullscreen === '')
  //       isFullscreen.value = true
  //   },
  //   { immediate: true }
  // )

  // watchEffect(() => {
  //   if (isFullscreen.value) return
  //   if (props.value && ProDialog.value) {
  //     nextTick(() => {
  //       draggable(ProDialog.value.dialogRef)
  //     })
  //   }
  // })
  // },
};
</script>
<style lang="scss" scoped>
.btn-box {
  width: 100%;
  text-align: center;
}
</style>
