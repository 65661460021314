<!--
 * @Description: 
 * @Autor: shh
 * @Date: 2022-11-14 16:31:15
 * @LastEditors: shh
 * @LastEditTime: 2022-11-17 10:11:27
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
}
</style>
