/*
 * @Description:
 * @Autor: sr
 * @Date: 2022-07-25 17:43:47
 * @LastEditors: sr
 * @LastEditTime: 2022-07-26 09:35:08
 */
import { filter, Subject } from "rxjs";

const subjects = {};

export function listen(type, callback = (data) => {}) {
  if (!subjects[type]) {
    subjects[type] = new Subject();
  }

  const eventSubject = subjects[type];

  const subscription = eventSubject
    .pipe(filter((value) => !!value.type))
    .pipe(filter((value) => value.type === type))
    .subscribe((value) => {
      callback(value.data);
    });
  return () => {
    subscription.unsubscribe();
  };
}

export function dispatch(type, data = {}) {
  if (!type) {
    return;
  }
  if (!subjects[type]) {
    subjects[type] = new Subject();
  }
  const eventSubject = subjects[type];

  eventSubject.next({ type, data });
}
