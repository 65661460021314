/*
 * @Description:
 * @Autor: shh
 * @Date: 2022-07-04 13:41:16
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-16 23:59:26
 */

import * as gm from "sm-crypto";
import setting from "@/config/setting.js";
const { title } = setting;
const SM2 = gm.sm2;
import { dictName, areaDict } from "@/utils/dict";

export function encodeFun(data, second) {
  data = SM2.doEncrypt(data ? "0" + data : "0", second);
  return data;
}

export const getPageTitle = (pageTitle) => {
  if (pageTitle) {
    return `${pageTitle}-${title}`;
  }
  return `${title}`;
};
export const formatDate = (date) => {
  const d = new Date(date);

  const y = d.getFullYear();

  const m = (d.getMonth() + 1).toString().padStart(2, "0");

  const r = d.getDate().toString().padStart(2, "0");

  const hh = d.getHours().toString().padStart(2, "0");

  const mm = d.getMinutes().toString().padStart(2, "0");

  const ss = d.getSeconds().toString().padStart(2, "0");

  return `${y}-${m}-${r} ${hh}:${mm}:${ss}`;
};

export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        time = parseInt(time);
      } else {
        time = time.replace(new RegExp(/-/gm), "/");
      }
    }

    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf("?") + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  let s = str.length;
  for (let i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;
    else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return "";
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return "";
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    })
  ).join("&");
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split("?")[1]).replace(/\+/g, " ");
  if (!search) {
    return {};
  }
  const obj = {};
  const searchArr = search.split("&");
  searchArr.forEach((v) => {
    const index = v.indexOf("=");
    if (index !== -1) {
      const name = v.substring(0, index);
      const val = v.substring(index + 1, v.length);
      obj[name] = val;
    }
  });
  return obj;
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement("div");
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

export function objectMerge(target, source) {
  if (typeof target !== "object") {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === "object") {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === "start") {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    const last = +new Date() - timestamp;

    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + "";
  const randomNum = parseInt((1 + Math.random()) * 65536) + "";
  return (+(randomNum + timestamp)).toString(32);
}

export const hasClass = function (obj, cls) {
  return obj.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
};

export const addClass = function (obj, cls) {
  if (!hasClass(obj, cls)) obj.className += " " + cls;
};

export const removeClass = function (obj, cls) {
  if (hasClass(obj, cls)) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    obj.className = obj.className.replace(reg, "");
  }
};

export const toggleClass = function (obj, cls) {
  if (hasClass(obj, cls)) {
    removeClass(obj, cls);
  } else {
    addClass(obj, cls);
  }
};

export function getScrollBarWidth() {
  let scrollBarWidth;

  const outer = document.createElement("div");
  outer.className = "el-scrollbar__wrap";
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  outer.style.position = "absolute";
  outer.style.top = "-9999px";
  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  outer.style.overflow = "scroll";

  const inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;
  outer.parentNode.removeChild(outer);
  scrollBarWidth = widthNoScroll - widthWithScroll;

  return scrollBarWidth;
}

export function copyValue(oldObj, newObj) {
  for (const key in oldObj) {
    oldObj[key] = newObj ? newObj[key] : null;
  }

  return oldObj;
}

export function getCharCode() {
  const codearr = [];
  for (var i = 0; i < 26; i++) {
    codearr.push(String.fromCharCode(65 + i));
  }
  return codearr;
}

export function filterName(value, arr) {
  if (value) {
    const res = arr.filter((items) => items["value"] === value);
    return res[0]?.label;
  } else {
    return null;
  }
}

export function getAllParentArr(list, id, name, child) {
  for (const i in list) {
    if (list[i][name] == id) {
      return [list[i][name]];
    }
    if (list[i][child]) {
      const node = getAllParentArr(list[i][child], id, name, child);
      if (node) {
        return node.concat(list[i][name]);
      }
    }
  }
}
export function getPid(id) {
  for (const i in areaDict) {
    if (areaDict[i]["value"] == id) {
      return [areaDict[i]["value"]];
    }
    if (areaDict[i]["children"]) {
      const node = getAllParentArr(
        areaDict[i]["children"],
        id,
        "value",
        "children"
      );
      if (node) {
        return node.concat(areaDict[i]["value"]).reverse();
      }
    }
  }
}

export function getChildIds(list = [], arr = []) {
  for (const item of list) {
    arr.push(item.id);
    if (item.children && item.children.length) {
      this.getChildIds(item.children, arr);
    }
  }
  return arr;
}

export function getItemByIdInTree(tree, value, path = "") {
  for (var i = 0; i < tree.length; i++) {
    let tempPath = path;
    tempPath = `${tempPath ? tempPath + "/" : tempPath}${tree[i].name}`; // 避免出现在最前面的/
    if (tree[i].id == value) {
      return tempPath;
    } else if (tree[i].children) {
      const reuslt = getItemByIdInTree(tree[i].children, value, tempPath);
      if (reuslt) {
        return reuslt;
      }
    }
  }
}

export function getItemInfoByIdInTree(tree, value) {
  for (var i = 0; i < tree.length; i++) {
    if (tree[i].value == value) {
      return tree[i];
    } else if (tree[i].children) {
      const reuslt = getItemInfoByIdInTree(tree[i].children, value);
      if (reuslt) {
        return reuslt;
      }
    }
  }
}
export function getIdname(value) {
  for (var i = 0; i < areaDict.length; i++) {
    if (areaDict[i].value == value) {
      return areaDict[i];
    } else if (areaDict[i].children) {
      const reuslt = getItemInfoByIdInTree(areaDict[i].children, value);
      if (reuslt) {
        let str = "";
        switch (value.split("-")[0]) {
          case "1":
            str = "旌阳区";
            break;
          case "2":
            str = "中江县";
            break;
          case "3":
            str = "罗江县";
            break;
          case "4":
            str = "广汉市";
            break;
          case "5":
            str = "什邡市";
            break;
          case "6":
            str = "绵竹市";
            break;
        }
        return str + "/" + reuslt.label;
      }
    }
  }
}

export function compareDate(DateOne, DateTwo) {
  var OneMonth = DateOne.substring(5, DateOne.lastIndexOf("-"));
  var OneDay = DateOne.substring(DateOne.length, DateOne.lastIndexOf("-") + 1);
  var OneYear = DateOne.substring(0, DateOne.indexOf("-"));
  var TwoMonth = DateTwo.substring(5, DateTwo.lastIndexOf("-"));
  var TwoDay = DateTwo.substring(DateTwo.length, DateTwo.lastIndexOf("-") + 1);
  var TwoYear = DateTwo.substring(0, DateTwo.indexOf("-"));
  if (
    Date.parse(OneMonth + "/" + OneDay + "/" + OneYear) >
    Date.parse(TwoMonth + "/" + TwoDay + "/" + TwoYear)
  ) {
    return true;
  } else {
    return false;
  }
}

export function unitPrice(price, area) {
  const p = (price * 1000) / (area * 1000);
  return p * 10000;
}
