/*
 * @Description: 关于用户登录的方法
 * @Autor: shh
 * @Date: 2022-11-15 10:28:47
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-17 00:05:38
 */
import router from "@/router";
import { login, logout } from "@/api/login";
import { orgClassApi } from "@/api/shop";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { encodeFun } from "@/utils/index";
import setting from "@/config/setting.js";
const { tokenStorage } = setting;

import { resetRouter } from "@/router";
const getDefaultState = () => {
  return {
    token: getToken(),
    name: "",
    avatar: "",
  };
};
const state = getDefaultState();
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
    localStorage.setItem(tokenStorage, token);
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
};
const actions = {
  // user login
  login({ commit }, userInfos) {
    return new Promise((resolve, reject) => {
      login(userInfos)
        .then((response) => {
          const { data } = response;
          commit("SET_TOKEN", "232322424324");
          setToken("232322424324");
          console.log(data[0]);
          const userInfo = data[0];
          userInfo.mobile = userInfos.username;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          commit("SET_NAME", "username");
          let power = "";
          if (userInfo.admin) {
            power = "admin";
          } else if (userInfo?.roleIds) {
            if (userInfo?.roleIds?.includes(1)) {
              power = "staffManager";
            } else {
              power = "staff";
            }
            localStorage.setItem("powerMobile", userInfos.username);
          }
          localStorage.setItem("power", power);
          location.reload();
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRouter({ commit, state }) {
    //获取用户路由
    return new Promise((resolve, reject) => {
      resources()
        .then((response) => {
          const { data } = response;
          localStorage.setItem("systemMenu", JSON.stringify(data));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 获取用户信息
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((response) => {
          const { data } = response;
          if (!data) {
            return reject("Verification failed, please Login again.");
          }
          const { name, id, parentId } = data[0];

          commit("SET_MAJOR", name);
          commit("SET_MAJORID", id);
          commit("SET_DEPARTMENTID", parentId);
          commit("SET_NAME", JSON.parse(localStorage.getItem("userInfo")).name);
          // if (data.length == 1) {
          orgClassApi().then((res) => {
            let parentName = "";
            res.data.forEach((ele) => {
              if (ele.id === parentId) {
                parentName = ele.name;
              }
            });
            commit("SET_DEPARTMENT", parentName);
            const obj = {
              ...JSON.parse(localStorage.getItem("userInfo")),
              major: name,
              majorId: id,
              department: parentName,
              departmentId: parentId,
            };
            localStorage.setItem("userInfo", JSON.stringify(obj));
          });
          // }

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          removeToken(); // must remove  token  first
          resetRouter();
          commit("RESET_STATE");
          resolve();
          setTimeout(() => {
            localStorage.clear();
            location.reload();
          }, 500);
          // router.replace({ path: "/login" });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
