/*
 * @Description: 
 * @Autor: shh
 * @Date: 2022-11-25 14:34:36
 * @LastEditors: shh
 * @LastEditTime: 2023-05-19 11:43:53
 */
import { get, post, put, del } from '@/utils/request'
let base = 'admin'
//=========登陆流程=========

export const login = (data) => post(base + '/v1/account/login', data)//登陆
export const logout = () => post(base + '/v1/account/logout')//登出
export const geojson = () => get('https://geo.datav.aliyun.com/areas_v3/bound/510600_full.json')

