/*
 * @Description:
 * @Autor: shh
 * @Date: 2022-11-15 10:44:16
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-15 10:14:27
 */
import Cookies from "js-cookie";

const TokenKey = "token";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
