<!-- 搜索条件 -->
<template>
  <div id="search-form-wrap" class="table-page-search-wrapper">
    <el-form
      v-if="searchListAll && searchListAll.length > 0"
      :model="queryParamVal"
      class="search-form"
      label-position="left"
      :inline="true"
      :label-width="labelWidth"
    >
      <template style="display: flex">
        <div
          v-for="(item, index) in searchListAll"
          v-show="index < 12 || advanced"
          :key="index"
          style="display: inline-block; margin-left: 10px"
        >
          <template v-if="item.searchSlot">
            <el-form-item :prop="item.dataIndex" :label="item.title + ' : '">
              <slot :name="item.searchSlot" :item="item"></slot>
            </el-form-item>
          </template>
          <el-form-item
            v-else
            :label="item.title + ' : '"
            style="display: inline-block"
          >
            <el-input
              v-if="item.valueType === 'input'"
              v-model="queryParamVal[item.dataIndex]"
              :placeholder="item.placeholder || item.title"
              clearable
            />

            <el-select
              v-else-if="item.valueType === 'select'"
              v-model="queryParamVal[item.dataIndex]"
              :placeholder="item.placeholder || item.title"
              :clearable="item.clearable"
              :filterable="item.filterable"
              style="width: 100%"
              :multiple="item.multiple"
            >
              <el-option
                v-for="(s, i) in item.option"
                :key="i"
                :label="s.label"
                :value="s.value"
                >{{ s.label }}
              </el-option>
            </el-select>
            <el-date-picker
              v-else-if="item.valueType === 'date-picker'"
              v-model="queryParamVal[item.dataIndex]"
              :type="item.pickerType"
              style="width: 100%; max-width: 270px"
              :format="item.pickerFormat"
              :value-format="item.pickerFormat"
            />
          </el-form-item>
        </div>
        <div style="display: inline-block; margin-left: 10px">
          <slot name="form"> </slot>
        </div>
        <!-- <slot name="header"> </slot>
        <slot name="footer"> </slot> -->
        <div class="table-page-search-submitButtons">
          <el-button
            type="primary"
            class="table-search"
            icon="el-icon-search"
            :loading="loading"
            @click="search"
            >查询</el-button
          >
          <el-button
            style="margin-left: 8px"
            icon="el-icon-refresh"
            @click="resetQueryParam"
            >重置</el-button
          >
          <slot name="header"> </slot>
          <a
            v-if="searchListAll.filter((i) => i.isSearch).length > 15"
            style="margin-left: 8px; font-size: 14px"
            @click="toggleAdvanced"
          >
            {{ advanced ? "收起" : "展开" }}
            <i :class="advanced ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
          </a>
        </div>
      </template>
    </el-form>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "SearchForm",
  props: {
    queryParam: {
      type: Object,
      default: () => {
        return {};
      },
    },
    labelWidth: {
      type: String,
      default: "104px",
    },
    searchList: {
      type: Array,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      advanced: false,
      queryParamVal: {},
      searchListAll: [],
    };
  },
  computed: {
    menuBgColor() {
      return "";
    },
  },
  watch: {
    searchList: {
      handler(val) {
        this.searchListAll = cloneDeep(val);
        this.init();
      },
      immediate: true,
      deep: true,
    },
    queryParam: {
      handler(val) {
        this.queryParamVal = cloneDeep(val);
      },
      immediate: true,
      deep: true,
    },
    queryParamVal: {
      handler(val) {
        this.$emit("searchChange", val);
      },
      immediate: true,
      deep: true,
    },
  },

  created() {},
  methods: {
    init() {
      for (let index = 0; index < this.searchListAll.length; index++) {
        const element = this.searchListAll[index];
        if (element.valueType && element.valueType === "select") {
          if (element.optionMth && element.option.length === 0) {
            // 如果有optionMth属性且option为空，则调用initMthOption方法
            this.initMthOption(element);
          } else if (
            !element.optionMth &&
            element.optionskey &&
            element.option.length > 0
          ) {
            // 如果没有optionMth属性且optionskey存在且option不为空，则调用initOption方法
            this.initOption(element);
          }
        }
      }
    },
    initMthOption(element) {
      element.optionMth().then((res) => {
        if (!res) return;
        let arr = [];
        if (element.optionskey) {
          arr = res.map((i) => {
            const obj = {};
            obj.label = i[element.optionskey.label];
            obj.value = i[element.optionskey.value];
            return obj;
          });
        } else {
          arr = res.data.map((i) => {
            const obj = {};
            obj.label = i;
            obj.value = i;

            return obj;
          });
        }

        element.defaultOption = element.defaultOption || [];
        element.option = [...element.defaultOption, ...arr];
      });
    },

    initOption(element) {
      const { option, optionskey } = element; // 使用解构赋值简化代码
      if (optionskey) {
        element.option = option.map((i) => {
          const { label, value } = i[optionskey]; // 使用解构赋值简化代码
          return { label, value };
        });
      } else {
        element.option = option.map((i) => {
          return { label: i, value: i };
        });
      }
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    resetQueryParam() {
      // 遍历查询参数值对象
      for (const key in this.queryParamVal) {
        // 如果查询参数值是数组，将其重置为空数组
        if (this.queryParamVal[key] instanceof Array) {
          this.queryParamVal[key] = [];
        } else {
          // 如果查询参数值不是数组，将其重置为空字符串
          this.queryParamVal[key] = "";
        }
      }
      this.$emit("search", this.queryParamVal);
    },
    search() {
      this.$emit("search", this.queryParamVal);
    },
  },
};
</script>
<style lang="scss" scoped>
// 数据列表 搜索条件
.table-page-search-wrapper {
  overflow-x: hidden;
  text-align: left;
  background: #ffffff;
  box-shadow: 0px 1px 18px 0px rgba(50, 118, 255, 0.11);
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 24px;
}

.table-page-search-submitButtons {
  margin-bottom: 18px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 220px;
  height: auto;

  .el-button {
    cursor: pointer;
    position: absolute;
    left: 100px;
    top: 6px;
    background: url(@/assets/images/lng/s-btn.png) no-repeat;
    background-size: 100% 100%;
    border: none;
    color: #fff;
  }

  .table-search {
    cursor: pointer;
    position: absolute;
    top: 6px;
    left: 0;
    background: url(@/assets/images/lng/s-btn.png) no-repeat;
    background-size: 100% 100%;
    border: none;
  }
}
</style>
