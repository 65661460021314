<!--
 * @Description: 
 * @Autor: shh
 * @Date: 2022-07-20 15:09:00
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-17 01:01:07
-->
<template>
  <div class="router-box">
    <ul>
      <li
        v-for="(item, index) in rouerList"
        v-if="
          power != 'admin'
            ? !roleIds.includes(item?.norole) && item?.power?.includes(power)
            : item?.power?.includes(power)
        "
        :key="index"
        :class="routerActive == item.name ? 'active' : ''"
        @click="setSild(item)"
      >
        <span>{{ item.meta.title }}</span>
      </li>
      <!-- <li
        @click="setSild(item)"
        v-for="item in rouerList"
        :class="routerActive == item.name ? 'active' : ''"
      >
        <span>{{ item.meta.title }}</span>
      </li> -->
    </ul>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      power: localStorage.getItem("power"),
      roleIds: JSON.parse(localStorage.getItem("userInfo"))?.roleIds || null,
      routerActive: null,
      rouerList: this.$router.options.routes.filter((item) => {
        if (localStorage.getItem("power") == "admin") {
          return item.name == "admin";
        } else {
          return item.name == "staff";
        }
      })[0].children,
    };
  },
  computed: {},
  watch: {},
  created() {
    if (JSON.parse(localStorage.getItem("menu"))) {
      this.routerActive = JSON.parse(localStorage.getItem("menu")).name;
      this.setSild(JSON.parse(localStorage.getItem("menu")), "ref");
    }
  },
  mounted() {},
  beforeDestroy() {},
  destroyed() {},

  methods: {
    setSild(data, type) {
      this.routerActive = data.name;
      this.$store.dispatch("app/setSlider", data);
      if (!type) {
        if (data.path == "/home") {
          this.$router.push({
            path: data.path,
          });
        } else {
          this.$router.push({
            path: data.children[0].path,
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.router-box {
  display: inline-block;
  margin-left: 30px;
  ul {
    list-style: none;
    height: 40px;
    margin: 12.5px 0;
    li {
      margin: 0;
      float: left;
      letter-spacing: 1px;
      line-height: 40px;
      height: 100%;
      background: url(../../../assets/images/lng/nav2.png) no-repeat;
      background-size: 100% 100%;
      padding: 0 30px;
      cursor: pointer;
      span {
        font-size: 16px;
        font-family: ShiShangZhongHeiJianTi;
        font-weight: bold;
        color: #ffffff;

        text-shadow: 0px 1px 1px rgba(53, 17, 17, 0.55);
      }

      &.active {
        background: url(../../../assets/images/lng/nav3.png) no-repeat;
        background-size: 100% 100%;
        span {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
