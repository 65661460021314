<!--
 * @Description: 
 * @Autor: shh
 * @Date: 2022-07-28 15:51:05
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-19 16:44:44
-->
<template>
  <div v-if="value" class="ProDialogWrap">
    <el-drawer
      :size="width"
      :wrapper-closable="false"
      :visible="value"
      v-bind="$attrs"
      :append-to-body="
        $attrs['append-to-body'] === undefined ? true : $attrs['append-to-body']
      "
      @close="handleClose"
    >
      <template #title>
        <slot name="title"></slot>
      </template>
      <slot></slot>
      <template v-if="!noFooter">
        <span class="dialog-footer">
          <slot name="footer"></slot>
          <div class="btn-box">
            <el-button
              v-if="!noconfirm"
              :loading="confirmLoading"
              class="success-button"
              @click="handleOk"
            >
              {{ confirmText }}
            </el-button>
            <el-button
              :loading="confirmLoading"
              class="cancel-button m-l-40"
              @click="handleClose"
              >取消</el-button
            >
          </div>
          <!-- <el-button v-if="!noconfirm" type="primary" @click="handleOk"
            >{{ confirmText }}
          </el-button>
          <el-button @click="handleClose">关 闭</el-button> -->
        </span>
      </template>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "ProDialog",
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    noconfirm: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: "保 存",
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "90%",
    },
  },
  data() {
    return {
      isFullscreen: false,
    };
  },
  computed: {
    // ...mapState({
    // 'routes': state => state.routes.routes
    // }),
    icon() {
      return this.isFullscreen ? "fullscreen-exit" : "fullscreen";
    },
  },
  created() {},
  // watch: {
  //   fullscreen: {
  //     handler: function () {
  //       if (attrs.fullscreen || attrs.fullscreen === '')
  //         this.isFullscreen = true
  //     },
  //     immediate: true
  //   },
  //   isFullscreen:{
  //      handler: function () {
  //       if (this.fullscreen || attrs.fullscreen === '')
  //         this.isFullscreen = true
  //     },
  //     immediate: true
  //   }
  // },

  // watchEffect(() => {
  //   if (isFullscreen.value) return
  //   if (props.value && ProDialog.value) {
  //     nextTick(() => {
  //       draggable(ProDialog.value.dialogRef)
  //     })
  //   }
  // })
  methods: {
    handleClose() {
      this.$emit("cancle");
      this.isFullscreen = false;
      // this.$emit('update:value', false)
    },
    handleOk() {
      this.$emit("ok");
      this.isFullscreen = false;
    },
    changeFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      // if (this.isFullscreen) {
      //   this.$refs.ProDialog.dialogRef.style.cssText += ';top:0px;left:0px;'
      // }
    },
    closeFullscreen() {
      this.isFullscreen = false;
      // if (this.isFullscreen) {
      //   this.$refs.ProDialog.dialogRef.style.cssText += ';top:0px;left:0px;'
      // }
    },
  },
  //  setup(props, { emit, attrs }) {
  // watch(
  //   () => attrs.fullscreen,
  //   () => {
  //     if (attrs.fullscreen || attrs.fullscreen === '')
  //       isFullscreen.value = true
  //   },
  //   { immediate: true }
  // )

  // watchEffect(() => {
  //   if (isFullscreen.value) return
  //   if (props.value && ProDialog.value) {
  //     nextTick(() => {
  //       draggable(ProDialog.value.dialogRef)
  //     })
  //   }
  // })
  // },
};
</script>
<style lang="scss" scoped>
.btn-box {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}
</style>
