<!--
 * @Description: 
 * @Autor: shh
 * @Date: 2022-07-20 15:09:00
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-17 01:02:02
-->
<template>
  <div>
    <ul class="menu">
      <li
        v-for="(item, index) in sidebar"
        v-if="
          power != 'admin'
            ? !item.hidden && !roleIds.includes(item?.norole)
            : !item.hidden
        "
        :key="index"
        :class="
          sidebarActive == item?.path ? 'menu-item  is-active' : 'menu-item'
        "
        @click="handleSelect(item?.path)"
      >
        {{ item?.meta?.title }}
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "",
  components: {},

  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      power: localStorage.getItem("power"),
      sidebarActive: "",
      roleIds: JSON.parse(localStorage.getItem("userInfo"))?.roleIds,
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },

  watch: {
    $route: {
      handler(val) {
        this.sidebarActive = val.path;
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  destroyed() {},

  methods: {
    handleSelect(key) {
      this.sidebarActive = key;
      this.$router.push({
        path: key,
      });
    },
  },
};
</script>
<style lang="scss">
.menu {
  margin: 30px 0;
  padding: 0;
}

.menu-item {
  width: 90%;
  line-height: 50px;
  margin: 10px 0 0 10%;
  padding: 0 !important;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  list-style: none;
  font-family: ShiShangZhongHeiJianTi;
  border-radius: 20px 0 0 20px;
}

.menu-item:hover,
.menu-item:active {
  color: #ff9320;
  cursor: pointer;
  border-radius: 20px 0 0 20px;
}

.menu-item.is-active {
  color: #ff9320;
  background: #fff;
  font-weight: 800;
  border-radius: 20px 0 0 20px;
  position: relative;
}

// =====
.el-menu {
  margin: 20px 0 !important;
  padding: 0;
  width: 100%;
  border: none !important;
  background: transparent !important;

  .el-submenu,
  .el-menu-item {
    width: 90%;
    min-height: 50px;
    margin: 10px 0 0 10%;
    padding: 0 !important;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    font-family: ShiShangZhongHeiJianTi;
  }

  .el-submenu__title {
    font-size: 16px;
    color: #fff;
    font-weight: 800;
    background: #011b47;
    border-radius: 20px 0 0 20px;
    padding: 0 !important;
  }

  .el-submenu__title:hover i {
    color: #ff9320;
  }

  .el-submenu__title:hover,
  .el-menu-item.is-active {
    color: #ff9320;
    background: #fff;
    font-weight: 800;
    border-radius: 20px 0 0 20px;
    position: relative;
  }

  .el-menu--inline {
    margin: 0 !important;
    width: 100%;
    border: none !important;
    // padding-left: 30px;
  }
}
</style>
