/*
 * @Description: 
 * @Autor: shh
 * @Date: 2022-11-25 14:34:00
 * @LastEditors: shh
 * @LastEditTime: 2023-03-24 16:33:39
 */
const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
 }
export default getters
