<template>
  <div class="mu-page">
    <div class="mu-header">
      <Header />
    </div>
    <div class="mu-menu">
      <Sidebar></Sidebar>
    </div>
    <div class="mu-content-box">
      <section class="app-main">
        <transition name="fade-transform" mode="out-in">
          <keep-alive ref="alive"> <router-view :key="key" /> </keep-alive>
        </transition>
      </section>
    </div>
    <div class="bottom"></div>
  </div>
</template>
<script>
import Header from "./components/System/Header.vue";
import Sidebar from "./components/System/Sidebar.vue";

export default {
  name: "Layout",
  components: {
    Header,
    Sidebar,
  },

  mixins: [],
  computed: {
    key() {
      return this.$route.path + Math.random();
    },

    sidebar() {
      return this.$store.state.app.sidebar;
    },

    device() {
      return this.$store.state.app.device;
    },

    fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },

    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },

  methods: {
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mu-page {
  height: 100%;
  width: 100vw;
  overflow: hidden;
  background: #011b47;
  background-image: url("@/assets/images/screen/left.png");
  background-repeat: no-repeat;
  background-size: 40px 100%;
  background-position: left bottom;

  .mu-header {
    height: 60px;
    width: 100%;
    // background: #fff;
  }

  .mu-menu {
    width: 140px;
    height: calc(100% - 60px);
    float: left;
    // background: url(@/assets/images/login/bottom.png) no-repeat;
    // background-size: 100% 100px;
    // background-position: bottom;
  }

  .mu-content-box {
    height: calc(100% - 90px);
    width: calc(100% - 150px);
    float: left;
    margin-top: 10px;
    border-radius: 20px;
    background: #fff;
    // padding: 15px;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-image: url("@/assets/images/screen/bottom.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: left;
  }
}

.app-wrapper {
  content: "";
  display: table;
  clear: both;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}

// ====
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.2s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translate(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
