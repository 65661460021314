/*
 * @Description:
 * @Autor: shh
 * @Date: 2022-07-28 11:23:25
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-15 10:15:17
 */
import { parseTime } from "@/utils";
import { areaDict, dictData } from "@/utils/dict";
import { getIdname } from "@/utils";

export default {
  parseTime(value) {
    return parseTime(value, "{y}-{m}-{d} {h}:{i}:{s}");
  },
  parseTime2(value) {
    return parseTime(value, "{y}-{m}");
  },
  dictName(value, type) {
    const res = dictData.filter((items) => items["value"] === type);
    const res1 = res[0].options.filter(
      (items) => items["value"] === parseInt(value)
    );
    return res1[0]?.label;
  },
  dictLabel(value, type) {
    const res = dictData.filter((items) => items["value"] === type);
    const res1 = res[0].options.filter((items) => items["value"] === value);
    return res1[0]?.label;
  },
  filterName(value, arr) {
    if (value) {
      const res = arr.filter((items) => items["value"] === parseInt(value));
      return res[0]?.label;
    } else {
      return null;
    }
  },
  filterLabel(value, arr) {
    if (value) {
      const res = arr.filter((items) => items["value"] === value);
      return res[0]?.label;
    } else {
      return null;
    }
  },
  formatDate(date) {
    const d = new Date(date);

    const y = d.getFullYear();

    const m = (d.getMonth() + 1).toString().padStart(2, "0");

    const r = d.getDate().toString().padStart(2, "0");

    const hh = d.getHours().toString().padStart(2, "0");

    const mm = d.getMinutes().toString().padStart(2, "0");

    const ss = d.getSeconds().toString().padStart(2, "0");

    return `${y}-${m}-${r} ${hh}:${mm}:${ss}`;
  },
  getIdname,
  shopInfo(value, arr) {
    if (value) {
      const res = arr.filter((items) => items["id"] === value);
      return res[0]?.name;
    } else {
      return null;
    }
  },
};
