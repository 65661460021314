<!--
 * @Description: 
 * @Autor: shh
 * @Date: 2022-07-20 15:09:00
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-19 17:41:11
-->
<template>
  <div>
    <div class="header1">
      <div class="center">
        <img class="logo" src="../../../assets/images/login/logo5.png" alt="" />
        <div>
          <div class="title">{{ proName }}</div>
        </div>
      </div>
      <div class="router">
        <Navigation></Navigation>
      </div>
      <div class="admin-box">
        <el-popover placement="bottom" width="100" trigger="click">
          <div>
            <div v-if="!power.includes('admin')" class="u-btn" @click="myinfo">
              个人信息
            </div>
            <div class="u-btn" @click="password">修改密码</div>
            <div class="u-btn" @click="logout">退出登录</div>
          </div>
          <div slot="reference" class="user">
            <i class="el-icon-user-solid"></i>
            <!-- <img src="../../../assets/images/lng/user.png" alt="" /> -->
            <span>{{ name }}</span>
            <i style="color: #fff" class="el-icon-caret-bottom"></i>
          </div>
        </el-popover>
      </div>
    </div>
    <!-- 修改密码 -->
    <ProDialog
      :value="dialogVisiblepwd"
      :title="'修改密码'"
      width="600px"
      :top="'10%'"
      @ok="handleDialogOk1"
      @cancle="dialogVisiblepwd = false"
    >
      <ProForm
        v-if="dialogVisiblepwd"
        ref="proform1"
        :form-param="form1"
        :form-list="formcolumns1"
        :layout="{ formWidth: '100%', labelWidth: '150px' }"
        @proSubmit="uppwd"
      >
      </ProForm>
    </ProDialog>
    <!-- 修改密码 -->
    <ProDialog
      :value="dialogVisibleinfo"
      :title="'个人信息'"
      width="850px"
      :top="'2%'"
      @ok="handleDialogOk2"
      @cancle="dialogVisibleinfo = false"
    >
      <ProForm
        v-if="dialogVisibleinfo"
        ref="proform2"
        :form-param="form2"
        :form-list="formcolumns2"
        :layout="{ formWidth: '100%', labelWidth: '150px' }"
        @proSubmit="upinfo"
      >
        <template #headImg>
          <el-upload
            class="avatar-uploader"
            action="/"
            :show-file-list="false"
            :multiple="false"
            accept="image/jpg,image/jpeg,image/png"
            :auto-upload="false"
            :on-change="headImgUpload"
          >
            <img v-if="form2.headImg" :src="form2.headImg" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </template>
        <template #cardImg1>
          <img
            v-if="form2.cardImg1"
            :src="form2.cardImg1"
            class="idcard-uploader idcard1"
          />
        </template>
        <template #cardImg2>
          <img
            v-if="form2.cardImg2"
            :src="form2.cardImg2"
            class="idcard-uploader idcard1"
          />
        </template>
      </ProForm>
    </ProDialog>
  </div>
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import { pwdApi, basicInfo, basicInfoEdit } from "@/api/system";
import Navigation from "./Navigation.vue";
import { validatePassword, checkMobile, validateRamk } from "@/utils/validate";
import { sexDict, roleDict, roleDict2 } from "@/utils/dict";
import { isEmpty, cloneDeep } from "lodash";

import setting from "@/config/setting.js";
const { proName } = setting;
export default {
  name: "",
  components: { Navigation },
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form1.pwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      power: localStorage.getItem("power"),
      name: JSON.parse(localStorage.getItem("userInfo")).username,
      proName: proName,
      dialogVisiblepwd: false,
      form1: {},
      formcolumns1: [
        {
          dataIndex: "oldPassword",
          title: "原密码",
          isForm: true,
          valueType: "input",
          inputType: "password",
          clearable: true,
          formSpan: 24,
          prop: [
            { required: true, validator: validatePassword, trigger: "blur" },
          ],
        },
        {
          dataIndex: "newPassword",
          title: "新密码",
          isForm: true,
          valueType: "input",
          inputType: "password",
          clearable: true,
          formSpan: 24,
          prop: [
            { required: true, validator: validatePassword, trigger: "blur" },
          ],
        },
      ],
      dialogVisibleinfo: false,
      form2: {},
      formcolumns2: [
        {
          dataIndex: "name",
          title: "姓名",
          disabled: true,
          valueType: "input",
          isForm: true,
          formSpan: 12,
          clearable: true,
        },
        {
          dataIndex: "mobile",
          isForm: true,
          title: "手机号",
          disabled: true,
          valueType: "input",
          clearable: true,
          formSpan: 12,
          // prop: [{ required: true, trigger: "change", validator: checkMobile }],
        },
        {
          dataIndex: "roleIds",
          title: "经纪人角色",
          isForm: true,
          valueType: "select",
          multiple: true,
          disabled: true,
          formSpan: 12,
          clear: true,
          clearable: true,
          option: roleDict,
        },
        {
          dataIndex: "sex",
          title: "性别",
          disabled: true,
          isForm: true,
          valueType: "select",
          formSpan: 12,
          clearable: true,
          option: sexDict,
        },
        {
          dataIndex: "cardNo",
          isForm: true,
          title: "身份证号",
          valueType: "input",
          clear: true,
          clearable: true,
          formSpan: 24,
          disabled: true,
        },
        {
          dataIndex: "headImg",
          isForm: true,
          title: "头像",
          clearable: true,
          formSpan: 24,
          formSlot: "headImg",
          prop: [
            {
              required: true,
              message: "请上传员工头像",
              trigger: "change",
            },
          ],
        },
        {
          dataIndex: "address",
          title: "住址",
          isForm: true,
          valueType: "input",
          clearable: true,
          formSpan: 24,
          prop: [
            {
              required: true,
              message: "请填入住址",
              trigger: "change",
            },
          ],
        },

        {
          dataIndex: "cardImg1",
          isForm: true,
          title: "身份证正面",
          clearable: true,
          formSpan: 24,
          formSlot: "cardImg1",
        },
        {
          dataIndex: "cardImg2",
          isForm: true,
          title: "身份证背面",
          clearable: true,
          formSpan: 24,
          formSlot: "cardImg2",
        },

        {
          dataIndex: "description",
          title: "简介",
          isForm: true,
          valueType: "input",
          inputType: "textarea",
          prop: [
            {
              required: true,
              trigger: "change",
              validator: validateRamk,
            },
          ],
        },
        {
          dataIndex: "Actions",
          width: 200,
          fixed: "right",
          title: "操作",
          align: "left",
          isTable: true,
          tableSlot: "actions",
        },
      ],
    };
  },
  computed: {
    // ...mapGetters(["name"]),
  },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  destroyed() {},

  methods: {
    handleCommand(command) {},
    //触发表单提交
    handleDialogOk1() {
      this.$refs["proform1"].handleSubmit();
    },

    //触发密码
    password() {
      this.form1 = {};
      this.dialogVisiblepwd = true;
      this.$nextTick((e) => {
        this.$refs["proform1"].resetFormParam();
      });
    },
    uppwd() {
      pwdApi(this.form1).then(() => {
        this.$notify.success("修改成功,请重新登录");
        this.dialogVisiblepwd = false;
        setTimeout(() => {
          this.$store.dispatch("user/logout");
        }, 500);
      });
    },
    //触发表单提交
    handleDialogOk2() {
      this.$refs["proform2"].handleSubmit();
    },
    myinfo() {
      basicInfo(JSON.parse(localStorage.getItem("userInfo")).id).then((res) => {
        res.data[0].roleIds = res.data[0].roleIds.split(",");
        this.form2 = cloneDeep(res.data[0]);
        this.dialogVisibleinfo = true;
        this.$nextTick((e) => {
          this.$refs["proform2"].resetFormParam();
        });
      });
    },
    upinfo() {
      const file = new FormData();
      for (const key in this.form2) {
        if (this.form2[key]) {
          file.append(key, this.form2[key]);
        }
      }
      basicInfoEdit(file).then(() => {
        this.$notify.success("修改成功");
        this.dialogVisibleinfo = false;
      });
    },
    headImgUpload(file) {
      if (file.status == "ready") {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$notify.error("上传头像图片大小不能超过 2MB!");
          return;
        }
        this.$set(this.form2, "headImg", URL.createObjectURL(file.raw));
        this.$set(this.form2, "headFile", file.raw);
      }
    },
    cardImg1Upload(file) {
      if (file.status == "ready") {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$notify.error("上传头像图片大小不能超过 2MB!");
          return;
        }
        this.$set(this.form2, "cardImg1", URL.createObjectURL(file.raw));
        this.$set(this.form2, "cardFile1", file.raw);
      }
    },
    cardImg2Upload(file) {
      if (file.status == "ready") {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$notify.error("上传头像图片大小不能超过 2MB!");
          return;
        }
        this.$set(this.form2, "cardImg2", URL.createObjectURL(file.raw));
        this.$set(this.form2, "cardFile2", file.raw);
      }
    },
    logout() {
      this.$confirm("是否确认退出登陆?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("user/logout");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header1 {
  background-image: url("@/assets/images/lng/header.png");
  background-repeat: no-repeat;
  background-size: 60% 100%;
  // background: transparent;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;

  .center {
    width: 400px;
    display: flex;
    text-align: center;

    img.logo {
      width: 35px;
      margin: 10px 10px 20px 40px;
      display: inline-block;
      border-radius: 10px;
    }

    .title {
      display: inline-block;
      font-weight: bold;
      color: #fff;
      font-size: 34px;
      letter-spacing: 5px;

      font-weight: 800;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: #fff;
      line-height: 60px;
      text-shadow: 1px 2px 0px rgba(16, 89, 153, 0.55), 1px 5px 6px #dce3ed;
    }
  }

  .admin-box {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding-top: 10px;
    float: right;
    cursor: pointer;

    .user {
      display: flex;
      align-items: center;
      margin-right: 40px;

      span {
        font-size: 18px;
        font-weight: 800;
        color: #ffffff;
      }

      .el-icon-user-solid {
        font-size: 18px;
        font-weight: 800;
        color: #ffffff;
        margin-right: 10px;
      }

      img {
        width: 30px;
        margin-right: 10px;
      }
    }
  }

  .out {
    width: 40px;
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
  }
}
</style>
