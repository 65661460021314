/*
 * @Description:
 * @Autor: shh
 * @Date: 2022-11-15 10:44:16
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-18 13:24:31
 */

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ["admin", "editor", "sa"];
  return valid_map.indexOf(str.trim()) >= 0;
}

export function validatePassword(rule, value, callback) {
  if (!value) {
    callback(new Error("请输入密码"));
  }
  if (value?.length < 6) {
    callback(new Error("密码不能少于6位"));
  } else {
    callback();
  }
}

export function checkIdCard(rule, value, callback) {
  const regIdCard =
    /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
  if (!value) {
    callback(new Error("请输入身份证号"));
  } else if (!regIdCard.test(value)) {
    callback(new Error("请输入正确的身份证号"));
  } else {
    callback();
  }
}
export function checkMobile(rule, value, callback) {
  // const regMobile =
  //   /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
  const regMobile = /^1\d{10}$/;
  if (!value) {
    callback(new Error("请输入手机号"));
  } else if (!regMobile.test(value)) {
    callback(new Error("请输入正确的手机号"));
  } else {
    callback();
  }
}
export function validateRamk(rule, value, callback) {
  if (!value) {
    callback(new Error("请输入内容"));
  }
  if (value?.length < 20) {
    callback(new Error("输入内容不能少于20个字"));
  } else {
    callback();
  }
}
