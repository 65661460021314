<template>
  <div class="pro-table" :class="sticky ? 'sticky' : ''">
    <SearchForm
      v-if="showSearch"
      ref="SearchForm"
      :search-list="searchList"
      :query-param="queryParam"
      :loading="listLoading"
      :label-width="labelWidth"
      @searchChange="searchChange"
      @search="refresh"
      @selectChange="selectChange"
    >
      <template #header>
        <slot name="searchHeader"></slot>
      </template>
      <template #form>
        <slot name="searchForm"></slot>
      </template>
      <template #footer>
        <slot name="searchFooter"></slot>
      </template>

      <template
        v-for="(item, index) in columns.filter((i) => i.isSearch)"
        #[item.searchSlot]
      >
        <div :key="index">
          <slot :name="item.searchSlot"></slot>
        </div>
      </template>
    </SearchForm>
    <div class="table-operator">
      <div class="solt">
        <slot name="btn"></slot>
      </div>
      <TableSetting
        v-if="showSetting"
        :columns="tableColumns"
        :refresh="refresh"
        @reRender="updateTable"
      />
    </div>

    <el-table
      ref="ProElTable"
      :key="key"
      v-loading="showLoading && listLoading"
      :data="data"
      v-bind="$attrs"
      :height="height"
      :stripe="stripe"
      style="overflow: hidden"
      :span-method="spanMethod"
      :tree-props="treeProps"
      @selection-change="handleSelectionChange"
    >
      <template slot="empty">
        <el-empty description="暂无数据"></el-empty>
      </template>
      <el-table-column
        v-if="showSelection"
        :reserve-selection="true"
        type="selection"
        align="center"
        width="55"
      />
      <el-table-column
        v-if="showIndex"
        label="序号"
        width="50"
        align="center"
        type="index"
      />
      <el-table-column
        v-for="item in tableColumns"
        :key="item.dataIndex"
        :label="item.title"
        :min-width="item.minWidth"
        :width="item.width"
        :align="item.align ? item.align : 'center'"
        :sortable="item.sortable || null"
        :prop="item.sortable ? item.dataIndex : null"
        :show-overflow-tooltip="true"
        :formatter="item.formatter"
      >
        <template v-if="!item.formatter" #default="scope">
          <span v-if="!item.tableSlot">{{ scope.row[item.dataIndex] }}</span>
          <span v-if="item.tableSlot === 'area'">
            {{ scope.row[item.dataIndex] }}
          </span>
          <span v-else-if="item.tableSlot === 'date'">
            {{ scope.row[item.dataIndex] | parseTime2 }}
          </span>
          <span v-else-if="item.tableSlot === 'datetime'">
            {{ scope.row[item.dataIndex] | parseTime }}
          </span>
          <slot
            v-else
            :name="item.tableSlot"
            :row="scope.row"
            :index="scope.$index"
          ></slot>
        </template>
      </el-table-column>
    </el-table>
    <div class="solt">
      <slot name="footer"></slot>
    </div>
    <Pagination
      v-if="showPagination && localPaginationNew.total"
      :page="localPaginationNew.pageNo"
      layout="total,jumper, prev, pager, next"
      :page-size="localPaginationNew.pageSize"
      :total="localPaginationNew.total"
      @pagination="loadData"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/index.vue"; // secondary package based on el-pagination
import SearchForm from "@/components/SearchForm/index.vue"; // SearchForm
import TableSetting from "@/components/ProTable/table-setting.vue"; // TableSetting
import { mapState } from "vuex";
import { cloneDeep } from "lodash";

export default {
  name: "ProTable",
  components: {
    Pagination,
    SearchForm,
    TableSetting,
  },
  props: {
    labelWidth: {
      type: String,
      default: "104px",
    },
    spanMethod: {
      type: Function,
      default: () => {},
    },
    lazyLoad: {
      type: Boolean,
      default: false,
    },
    stripe: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    queryParam: {
      type: Object,
      default: () => {
        return {};
      },
    },
    treeProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    height: {
      type: Number,
      default: 750,
    },
    pageSize: {
      type: Number,
      default: 15,
    },
    showSetting: {
      type: Boolean,
      default: false,
    },
    showIndex: {
      type: Boolean,
      default: true,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    localPagination: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      search: "",
      searchList: [],
      key: 0,
      list: null,
      listLoading: true,
      tableColumns: [],
      paramesdata: {},
      localPaginationNew: {
        pageNo: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    ...mapState({
      // 'isMobile': state => state.setting.isMobile,
      isLogo: (state) => state.setting.isLogo,
      // 'routes': state => state.routes.routes
    }),
    menuBgColor() {
      return "";
    },
  },
  watch: {
    columns: {
      handler() {
        this.initColumns();
      },
      deep: true,
    },
    localPagination: {
      handler(val) {
        this.localPaginationNew = cloneDeep(val);
      },
      deep: true,
    },
  },
  created() {
    if (!this.lazyLoad) {
      this.initColumns();
      // this.loadData()
    }
  },
  methods: {
    initColumns() {
      // 过滤出表格列
      this.tableColumns = cloneDeep(this.columns.filter((i) => i.isTable));
      this.searchList = cloneDeep(this.columns)
        .filter((i) => i.isSearch)
        .map((i) => {
          return i;
        });
    },
    loadData(val) {
      // 设置本地分页的页码
      this.localPaginationNew.pageNo = val.page;
      // 设置请求参数的页码
      this.paramesdata.pageNo = val.page;
      // 开启加载状态
      this.listLoading = true;
      // 合并请求参数
      this.paramesdata = Object.assign(
        {},
        this.localPaginationNew,
        this.paramesdata,
        cloneDeep(val)
      );
      // 删除请求参数中的 page 和 total 字段
      delete this.paramesdata.page;
      delete this.paramesdata.total;
      // 触发 loadData 事件，传递请求参数
      this.$emit("loadData", this.paramesdata);
    },
    refresh(val) {
      // 重置分页页码为1
      this.localPaginationNew.pageNo = 1;
      // 将val和分页信息合并为一个新对象
      this.paramesdata = {
        ...cloneDeep(val),
        ...this.localPaginationNew,
      };
      // 删除page和total属性
      delete this.paramesdata.page;
      delete this.paramesdata.total;
      // 触发loadData事件，传递新参数
      this.$emit("loadData", this.paramesdata);
    },
    updateTable() {
      this.key = this.key += 1;
    },
    selectChange(val) {
      this.$emit("selectChange", val);
    },
    searchChange(val) {
      this.$emit("searchChange", val);
    },
    handleSelectionChange(val) {
      this.$emit("handleSelectionChange", val);
    },
  },
};
</script>
