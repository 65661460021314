/*
 * @Description:
 * @Autor: shh
 * @Date: 2022-07-15 13:57:16
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2023-12-15 10:38:21
 */
import Vue from "vue";
import App from "./App.vue";
import "./config/permission";
import VueResource from "vue-resource";
import VueDragResize from "vue-drag-resize";
Vue.component("VueDragResize", VueDragResize);
import * as echarts from "echarts";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/styles/index.scss";
import "@/utils/use";
import "@/utils/drag.js";
import { listen, dispatch } from "./config/eventBus.js";
// import JsonExcel from 'vue-json-excel'
// Vue.component('downloadExcel', JsonExcel)
import BaiduMap from "vue-baidu-map";
import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);
import "../node_modules/swiper/dist/css/swiper.css";
import filters from "@/utils/globalFilters";
Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]));
Vue.use(Element);
import "@/config/rem";
import router from "./router";
import store from "./store";
Vue.config.productionTip = false;
Vue.use(VueResource);
import "@/utils/drag.js";
Vue.prototype.$echarts = echarts;
window.eventBus = new Vue();
Vue.mixin({
  props: {
    eventKey: String,
  },
  created() {
    const name = this.$options.name;
    if (name) {
      this._listenEvents(name);
    }
  },
  methods: {
    globalDispatch(target, params) {
      return new Promise((resolve) => {
        if (typeof target === "object") {
          dispatch(target.target, {
            payload: { eventKey: target.eventKey || "", params },
            callback(data) {
              resolve(data);
            },
          });
        } else {
          dispatch(target, {
            payload: { params },
            callback(data) {
              resolve(data);
            },
          });
        }
      });
    },
    _listenEvents(prefix) {
      const _this = this;
      let closeActions = [];
      const handlers = Object.keys(_this)
        .filter((key) => {
          const handler = _this[key];
          if (
            key.startsWith("_") ||
            key.startsWith("$") ||
            typeof handler !== "function" ||
            key === "globalDispatch"
          ) {
            return false;
          }
          return true;
        })
        .map((key) => ({ key, handler: _this[key] }));

      handlers.forEach(({ key, handler }) => {
        closeActions.push(
          listen(
            `${prefix}/${key}`,
            ({ payload = {}, callback = () => {} } = {}) => {
              const params = payload.params || [];
              if (payload.eventKey || _this.eventKey) {
                if (payload.eventKey !== _this.eventKey) {
                  return;
                }
              }
              const result = handler.apply(_this, params);
              if (result instanceof Promise && result.then) {
                result.then((data) => {
                  callback(data);
                });
              } else {
                callback(result);
              }
            }
          )
        );
      });
      _this.$once("hook:destroyed", () => {
        closeActions.forEach((close) => close());
        closeActions = [];
      });
    },
  },
});
Vue.use(BaiduMap, {
  ak: "Mmhqa6bz6OYXOmErpLvjb3qz6EAtHSdi",
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
