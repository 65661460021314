<!-- 通用Form -->
<template>
  <el-form
    v-if="showForm"
    ref="ProForm"
    v-loading="confirmLoading"
    :model="originalFormParams"
    :label-position="labelPosition"
    :label-width="layout.labelWidth || '120px'"
    :rules="rules"
    :style="{ width: layout.formWidth || '560px' }"
    style="margin: 0 auto"
  >
    <el-row
      v-for="(row, j) in formRows"
      :key="j"
      :class="{ 'card-row': formRows.length > 1 }"
    >
      <el-col v-if="formRows.length > 1" :span="24" class="card-title">
        <slot :name="'title' + j"></slot>
      </el-col>

      <el-row
        class="card-body"
        :gutter="layout.gutter || 20"
        style="width: 100%"
      >
        <el-col
          v-for="(item, index) in row.filter((i) => i.isForm)"
          :key="index"
          :style="{ clear: item.clear ? 'both' : '' }"
          :span="item.formSpan || 24"
          :xs="item.form_xs || item.formSpan"
          :sm="item.form_sm || item.formSpan"
          :md="item.form_md || item.formSpan"
          :lg="item.form_lg || item.formSpan"
          :xl="item.form_xl || item.formSpan"
        >
          <template v-if="item.isCut">
            <div v-if="item.isCut" class="el-tit">
              <span>{{ item.title }}</span>
            </div>
          </template>

          <template v-if="!item.isCut">
            <template v-if="item.formOtherSlot">
              <slot :name="item.formOtherSlot" :item="item"></slot>
            </template>
            <template v-else-if="item.formSlot">
              <el-form-item
                :prop="
                  openType === 'info' ? '' : item.prop ? item.dataIndex : ''
                "
                :label="item.title + ' : '"
              >
                <slot :name="item.formSlot" :item="item"></slot>
              </el-form-item>
            </template>
            <el-form-item
              v-else
              :prop="
                openType === 'info' || openType === 'audit'
                  ? ''
                  : item.prop
                  ? item.dataIndex
                  : ''
              "
              :label="item.title + ' : '"
            >
              <!-- <el-tooltip
              :content="originalFormParams[item.dataIndex]"
              effect="light"
            >
              <div class="info-text" v-if="item.valueType === 'text'">
                {{ originalFormParams[item.dataIndex] }}
              </div>
            </el-tooltip> -->
              <el-popover
                v-if="
                  originalFormParams[item.dataIndex] &&
                  item.valueType === 'text'
                "
                placement="bottom"
                trigger="click"
                :content="originalFormParams[item.dataIndex]"
              >
                <div slot="reference" class="info-text">
                  {{ originalFormParams[item.dataIndex] }}
                </div>
              </el-popover>
              <div
                v-if="
                  !originalFormParams[item.dataIndex] &&
                  item.valueType === 'text'
                "
                class="info-text"
              ></div>
              <el-input
                v-if="item.valueType === 'input'"
                v-model="originalFormParams[item.dataIndex]"
                v-bind="item.attrs"
                :disabled="
                  item.disabled || openType === 'info' || openType === 'audit'
                "
                :min="item.inputType === 'number' ? 1 : null"
                :show-word-limit="
                  item.inputType == 'textarea' || item.wordlimit != null
                "
                :type="item.inputType || 'text'"
                minlength="20"
                :maxlength="item.wordlimit || 600"
                :placeholder="item.placeholder || '请输入' + item.title"
                :show-password="item.inputType === 'password'"
              >
                <template v-if="item.attrs && item.attrs.prepend" #prepend>{{
                  item.attrs.prepend
                }}</template>
                <template v-if="item.attrs && item.attrs.append" #append>{{
                  item.attrs.append
                }}</template>
              </el-input>
              <el-input-number
                v-if="item.valueType === 'input-number'"
                v-model="originalFormParams[item.dataIndex]"
                v-bind="item.attrs"
                min="1"
                :disabled="
                  item.disabled || openType === 'info' || openType === 'audit'
                "
                :placeholder="item.placeholder || '请输入' + item.title"
                style="width: 100%"
              />

              <el-switch
                v-else-if="item.valueType === 'switch'"
                v-model="originalFormParams[item.dataIndex]"
                :active-value="item.activeValue"
                :inactive-value="item.inactiveValue"
                :disabled="
                  item.disabled || openType === 'info' || openType === 'audit'
                "
              />
              <el-date-picker
                v-else-if="item.valueType === 'date-picker'"
                v-model="originalFormParams[item.dataIndex]"
                v-bind="item.attrs"
                :type="item.pickerType"
                :disabled="
                  item.disabled || openType === 'info' || openType === 'audit'
                "
                :placeholder="item.placeholder || '请选择' + item.title"
                clearable
                style="width: 100%"
                :format="item.pickerFormat"
                :value-format="item.valueFormat"
              />
              <el-time-select
                v-else-if="item.valueType === 'time-select'"
                v-model="originalFormParams[item.dataIndex]"
                v-bind="item.attrs"
                :type="item.pickerType"
                :disabled="
                  item.disabled || openType === 'info' || openType === 'audit'
                "
                :placeholder="item.placeholder || '请选择' + item.title"
                clearable
                style="width: 100%"
                :format="item.pickerFormat"
                :value-format="item.valueFormat"
              />
              <el-time-picker
                v-else-if="item.valueType === 'time-picker'"
                v-model="originalFormParams[item.dataIndex]"
                is-range
                v-bind="item.attrs"
                :type="item.pickerType"
                :disabled="
                  item.disabled || openType === 'info' || openType === 'audit'
                "
                :placeholder="item.placeholder || '请选择' + item.title"
                clearable
                style="width: 100%"
                :format="item.pickerFormat"
                :value-format="item.valueFormat"
              />
              <el-select
                v-else-if="item.valueType === 'select'"
                v-model="originalFormParams[item.dataIndex]"
                :disabled="
                  item.disabled || openType === 'info' || openType === 'audit'
                "
                :placeholder="item.placeholder || '请选择' + item.title"
                filterable
                :multiple="item.multiple ? true : false"
                clearable
                style="width: 100%"
                @visible-change="$forceUpdate()"
              >
                <el-option
                  v-for="s in item.option.filter((i) => i.value !== '')"
                  :key="'select' + s.value"
                  :value="s.value"
                  :label="s.label"
                  >{{ s.label }}</el-option
                >
              </el-select>

              <el-checkbox-group
                v-else-if="item.valueType === 'checkbox'"
                v-model="originalFormParams[item.dataIndex]"
                size="small"
              >
                <el-checkbox
                  v-for="s in item.option"
                  :key="s.value"
                  border
                  :label="s.value"
                  :disabled="
                    item.disabled || openType === 'info' || openType === 'audit'
                  "
                  >{{ s.label }}</el-checkbox
                >
              </el-checkbox-group>
              <el-checkbox-group
                v-else-if="item.valueType === 'checkbutton'"
                v-model="originalFormParams[item.dataIndex]"
                size="small"
              >
                <el-checkbox-button
                  v-for="s in item.option"
                  :key="s.value"
                  :label="s.value"
                  :disabled="
                    item.disabled || openType === 'info' || openType === 'audit'
                  "
                  >{{ s.label }}</el-checkbox-button
                >
              </el-checkbox-group>
              <el-radio-group
                v-else-if="item.valueType === 'radio'"
                v-model="originalFormParams[item.dataIndex]"
              >
                <el-radio
                  v-for="(s, i) in item.option"
                  :key="i"
                  :label="s.value"
                  :disabled="
                    item.disabled || openType === 'info' || openType === 'audit'
                  "
                  >{{ s.label }}</el-radio
                >
              </el-radio-group>
              <span v-if="item.unit" class="unit">{{ item.unit }}</span>
            </el-form-item>
          </template>
        </el-col>
      </el-row>
    </el-row>

    <el-form-item
      v-if="$slots && $slots.footerBtn"
      label-width="0"
      style="margin-top: 24px; text-align: center"
    >
      <slot name="footerBtn"></slot>
    </el-form-item>
  </el-form>
</template>

<script>
// import SendCode from '@/components/sendCode/index.vue'
import { cloneDeep } from "lodash";
// import { onBeforeUnmount } from 'vue'

export default {
  name: "ProForm",
  // components: { SendCode },
  props: {
    formParam: {
      type: Object,
      default: () => {
        return {};
      },
    },
    layout: {
      type: Object,
      default: () => {
        return {};
      },
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
    labelPosition: {
      type: String,
      default: "right",
    },
    openType: {
      type: String,
      default: "add",
    },
    formList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      showForm: false,
      rules: {},
      formRows: [],
      formListAll: [],
      originalFormParams: [],
      checkList: [],
    };
  },
  computed: {},
  watch: {
    formList: {
      handler: function (val) {
        this.formListAll = cloneDeep(val);
        this.init();
      },
      immediate: true,
      deep: true,
    },
    formParam: {
      handler: function (val) {
        this.resetFormParam();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    getSelect() {
      this.$forceUpdate();
    },
    init() {
      // 克隆表单参数
      this.originalFormParams = cloneDeep(this.formParam);
      // 遍历表单列表
      this.formListAll.forEach((element) => {
        // 判断是否需要显示该表单项
        if (element.isShowFormItem) {
          element.isForm = element.isShowFormItem(this.formParam);
        }
        // 如果不需要显示该表单项，则跳过
        if (!element.isForm) return;

        // 如果该表单项是下拉框
        if (element.valueType && element.valueType === "select") {
          // 如果需要异步获取下拉框选项
          if (element.optionMth) {
            if (element.option.length === 0) {
              this.initMthOption(element);
            }
          }
          // 如果下拉框选项已经存在
          if (!element.optionMth && element.optionskey) {
            if (element.option.length > 0) {
              this.initOption(element);
            }
          }
        }
      });

      // 初始化表单行
      this.formRows = [this.formListAll];
      // 初始化表单验证规则
      this.initRules();
      // 显示表单
      this.showForm = true;
    },
    initMthOption(element) {
      element.optionMth().then((res) => {
        if (!res) return;
        const { data } = res;
        if (data) {
          let arr = [];
          if (element.optionskey) {
            arr = data.map((i) => {
              const obj = {};
              obj.label = element.optionskey.label2
                ? i[element.optionskey.label] +
                  "_" +
                  i[element.optionskey.label2]
                : i[element.optionskey.label];
              obj.value = i[element.optionskey.value];
              return obj;
            });
          } else {
            arr = data.map((i) => {
              const obj = {};
              obj.label = i;
              obj.value = i;
              return obj;
            });
          }
          element.defaultOption = element.defaultOption || [];
          element.option = [...element.defaultOption, ...arr];
        } else {
          element.option = [];
        }
      });
    },
    initOption(element) {
      const { option, optionskey } = element; // 使用解构赋值简化代码

      if (optionskey) {
        element.option = option.map((i) => {
          const { label, value } = i[optionskey]; // 使用解构赋值简化代码
          return { label, value };
        });
      } else {
        element.option = option.map((i) => {
          return { label: i, value: i };
        });
      }
    },
    initRules() {
      this.rules = {};
      // 遍历表单列表
      this.formListAll.forEach((i) => {
        // 如果该表单项有prop属性，则将其加入到验证规则中
        if (i.prop) {
          this.rules[i.dataIndex] = i.prop;
        }
      });
    },
    linkageForm() {
      if (this.formListAll.filter((i) => i.isShowFormItem).length === 0) return;
      for (const list of this.formRows) {
        for (const item of list) {
          if (item.isShowFormItem) {
            item.isForm = item.isShowFormItem(this.formParam);
          }
        }
      }
    },
    resetFormParam() {
      // 将 this.formParam 的值赋给 this.originalFormParams
      this.originalFormParams = Object.assign(
        this.formParam,
        this.originalFormParams
      );
      // 等待 DOM 更新
      this.$nextTick(() => {
        // 如果存在名为 "ProForm" 的 ref
        if (this.$refs["ProForm"]) {
          // 清除表单验证
          this.$refs["ProForm"].clearValidate();
        }
      });
    },
    handleSubmit() {
      // 返回一个 Promise 对象
      return new Promise((resolve, reject) => {
        // 调用 ProForm 组件的 validate 方法，传入一个回调函数
        this.$refs["ProForm"].validate((valid) => {
          // 如果表单验证通过
          if (valid) {
            // 调用 resolve 方法，表示 Promise 对象的状态变为 resolved
            resolve();
            // 触发 proSubmit 事件，传入表单参数和一个回调函数
            this.$emit("proSubmit", this.originalFormParams, (states) => {
              // 如果回调函数的参数为 "fulfilled"，则调用 resetFormParam 方法
              if (states === "fulfilled") this.resetFormParam();
            });
          } else {
            // 如果表单验证不通过，则直接返回
            return;
            // reject()
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-row {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  transition: 0.3s;

  .card-title {
    padding: 18px 20px;
    border-bottom: 1px solid #ebeef5;
    box-sizing: border-box;
  }

  .card-body {
    padding: 20px;
  }
}

.card-row + .card-row {
  margin-top: 20px;
}

.info-text {
  width: 100%;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unit {
  position: absolute;
  right: 6px;
  font-size: 12px;
  color: #6c6e72;
}

.el-tit {
  width: 250px;
  background: url(@/assets/images/lng/title1.png) no-repeat;
  background-size: 100% 100%;
  margin: 10px 0;

  span {
    margin-left: 30px;
    line-height: 40px;
    display: inline-block;
    color: #1a51e2;
    font-family: ShiShangZhongHeiJianTi;
    font-size: 16px;
  }
}
</style>
