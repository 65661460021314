/*
 * @Description: 
 * @Autor: shh
 * @Date: 2022-11-25 14:34:36
 * @LastEditors: shh
 * @LastEditTime: 2023-09-14 11:28:26
 */

import { get, post, put, del } from '@/utils/request'
let power = localStorage.getItem('power')
let base = power == 'staffManager' ? 'staff' : power


//=========菜单管理=========
export const menuListApi = (data) => get(base + '/menu', data)// 分页查询
export const menuAddApi = (data) => post(base + '/magina/manage/menu', data)// 新增
export const menuEditApi = (data) => put(base + '/magina/manage/menu', data)// 修改
export const menuDelApi = (id) => del(base + `/magina/manage/menu/${[id]}`)// 删除
//=========角色管理=========
export const roleListApi = (data) => get(base + '/role', data)// 分页查询
export const roleAddApi = (data) => post(base + '/magina/manage/role', data)// 新增
export const roleEditApi = (data) => put(base + '/magina/manage/role', data)// 修改
export const roleDelApi = (id) => del(base + `/magina/manage/role/${[id]}`)// 删除

//=========字典管理=========
export const dictListApi = (data) => get(base + '/dict', data)// 分页查询  
export const dictAddApi = (data) => post(base + '/magina/manage/department', data)// 新增
export const dictEditApi = (data) => put(base + '/magina/manage/department', data)// 修改
export const dictDelApi = (id) => del(base + `/magina/manage/department/${[id]}`)// 删除
export const dictItemApi = (id, data) => get(base + `/dictItem`, data)// 字典项
export const dictItemDelApi = (id, data) => del(base + `/magina/manage/department-user/${id}/${[data]}`)// 移除字典项
export const dictItemEditApi = (id, data) => get(base + `/magina/manage/department-user/add-member-page/${id}`, data)// 编辑字典项
export const dictItemAddApi = (id, data) => post(base + `/magina/manage/department-user/${id}`, data)// 新增字典项
//=========经纪人管理=========
export const userListApi = (data) => get(base + '/v1/manage/staff/list', data)// 分页查询
export const userAddApi = (data) => post(base + '/v1/manage/staff/', data)// 新增
export const userEditApi = (data) => put(base + '/v1/manage/staff/basicInfo', data)// 修改
export const userDelApi = (id) => del(base + `/v1/manage/staff/${id}`)// 删除
export const userInfoApi = (id) => get(base + `/v1/manage/staff/${id}`)// 详情
export const userDisabledApi = (id) => put(base + `/v1/manage/staff/disable/${id}`)// 停用
export const userEnabledApi = (id) => put(base + `/v1/manage/staff/enable/${id}`)// 启用
export const userListAll = () => get(base + '/v1/manage/staff/list', { pageNo: 1, pageSize: 10000 })// 所有用户
export const userCheckApi = (id) => put(base + `/v1/manage/staff/approved/${id}`)// 审核
export const userResetApi = (id) => put(base + `/v1/manage/staff/reset/password/${id}`)// 重置密码

//=========系统管理员=========
export const adminListApi = (data) => get(base + '/v1/account/list', data)// 分页查询
export const adminAddApi = (data) => post(base + '/v1/account/', data)// 新增
export const adminEditApi = (data) => put(base + '/v1/account/password', data)// 修改密码
export const adminDelApi = (id) => del(base + `/v1/account/${[id]}`)// 删除

//=========系统设置=========
export const setListApi = (data) => get(base + '/v1/manage/settings/list', data)// 分页查询
export const setAddApi = (data) => post(base + '/v1/manage/settings/', data)// 新增
export const setEditApi = (id, data) => put(base + `/v1/manage/settings/${id}`, data)// 修改密码
export const setDelApi = (id) => del(base + `/v1/manage/settings/${id}`)// 删除
export const setInfoApi = (id) => get(base + `/v1/manage/settings/${id}`)// 详情

//=========banner设置=========
export const bannerListApi = (data) => get(base + '/v1/manage/banner/list', data)// 分页查询
export const bannerAddApi = (data) => post(base + '/v1/manage/banner/', data)// 新增
export const bannerEditApi = (data) => put(base + `/v1/manage/banner/info`, data)// 修改密码
export const bannerDelApi = (id) => del(base + `/v1/manage/banner/${id}`)// 删除

//=========修改密码=========
export const pwdApi = (data) => put(base + '/v1/account/password', data)
//=========个人信息=========
export const basicInfo = (id) => get(base + `/v1/account/${id}`)
export const basicInfoEdit = (data) => put(base + '/v1/account/basicInfo', data)


//=========用户列表=========
export const wxUserListApi = (data) => get(base + '/v1/wxUser/list', data)// 分页查询



export const statisticApi = (data) => get(base + '/v1/account/statistic', data)// 分页查询
