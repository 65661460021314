/*
 * @Description: rem与px转换
 * @Autor: shh
 * @Date: 2022-07-14 10:43:31
 * @LastEditors:
 * @LastEditTime: 2023-12-15 10:05:23
 */
const setting = require("./setting");
const { viewportRem } = setting;

(function (doc, win) {
  var docEl = doc.documentElement;
  var resizeEvt =
    "orientationchange" in window ? "orientationchange" : "resize";
  var setRem = function () {
    var clientWidth = docEl.clientWidth;
    if (!clientWidth) return;
    docEl.style.fontSize =
      (clientWidth / viewportRem.viewportWidth) * viewportRem.rootValue + "px";
  };
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, setRem, false);
  doc.addEventListener("DOMContentLoaded", setRem, false);
  win.addEventListener("pageShow", setRem, false);
})(document, window);
