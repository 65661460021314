/*
 * @Description: 框架相关的一些配置
 * @Autor: shh
 * @Date: 2022-04-17 19:20:26
 * @LastEditors: shanhaihong 3045755645@qq.com
 * @LastEditTime: 2024-01-30 13:52:21
 */

const setting = {
  title: "海拓房源管控平台",
  proName: "海拓房源管控平台",
  viewportRem: {
    viewportWidth: 1920,
    rootValue: 16,
    selectorBlackList: ["wu"],
    propList: ["*"],
  },
  viewportVw: {
    viewportWidth: 1920,
    minPixelValue: 5,
    propList: ["*"],
    selectorBlackList: [],
  },
  cors: true,
  contentType: "application/json;charset=UTF-8",
  otherContentType: "application/x-www-form-urlencoded;charset=UTF-8",
  messageDuration: 3000,
  requestTimeout: 200000,
  successCode: [0],
  invalidCode: 401,
  noPermissionCode: 4001,
  errorCode: 500,
  base: "/gdjg/",
  publicDir: "public",
  cacheDir: "node_modules/.vite",
  outDir: "dist",
  assetsDir: "static/",
  sourcemap: false,
  chunkSizeWarningLimit: 2000,
  cssCodeSplit: true,
  brotliSize: false,
  host: "0.0.0.0",
  port: "8089",
  strictPort: false,
  open: true,
  progressBar: true,
  defaultOpeneds: ["/comp", "/errorPage", "/chart"],
  uniqueOpened: false,
  tokenName: "X-AUTH-TOKEN",
  loginInterception: true,
  tokenStorage: "token",
  langKey: "i18nLang",
  themeKey: "theme",
  lang: "zh-cn",
  storage: "localStorage",
  copyname: "四川海拓房地产经纪有限公司",
  copyright: "© 蜀ICP备2023008570号-1",
  version: "V1.1.7",
  footerCopyright: true,
  keepAliveMaxNum: 99,
  authentication: "intelligence",
  recordRoute: true,
  routesWhiteList: ["/login", "/register", "/404", "/401", "/index"],
  debounce: [],
  extensions: [".mjs", ".js", ".ts", ".jsx", ".tsx", ".json"],
  logLevel: "info",
  clearScreen: false,
  drop_console: true,
  drop_debugger: true,
};

module.exports = setting;
